/* eslint-disable no-self-compare */
/* eslint-disable camelcase */
import React, {useEffect, useState} from 'react'
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import {Box} from "@mui/material";
import Slide from '@mui/material/Slide';
import {host,postRequest } from '../../../utils/NetworkHandler';



const Transition = React.forwardRef((props, ref) => <Slide direction="left" ref={ref} {...props} />);


export default function EditJobCategoryDialog(props) {
    const [maxWidth, setMaxWidth] = React.useState('xs');
    const [fullWidth, setFullWidth] = React.useState(true);
    const [loading, setLoading] = useState(false);

    const [category, setCategory] = useState("");
    const [file, setfile] = useState("");

    const [categoryHelper, setCategoryHelper] = useState("");
    const [fileHelper, setfileHelper] = useState("");
  

   
    const editObj  = props.editobj;
    // console.log( props)

    useEffect( () => {
      if(editObj !== null && editObj !== undefined){
        
        const category_name = editObj.job_category;
        const file_path = editObj.type_image;

        console.log(editObj.volunteer_type)
    
        setCategory(category_name);
        setfile(file_path);
      }
    }, [editObj,] )
    

   

    const requiredText = "This field is required";


    const saveAccount = () => {
        setLoading(true);
        let formValidated = true;
       
        if(category === ""){
            formValidated = false;
            setCategoryHelper(requiredText);
        }
        if(file === ""){
          formValidated = false;
          console.log(formValidated)
          setfileHelper(requiredText);
      }
      if(file === file)
      {
        setfile(file);
      }
      console.log("clciked")
        if(formValidated){
          const data = {'id':editObj.id ,'category': category, 'image': file}
            postRequest("/job/api/editcategory", data, onSuccess, onError, true);
        }
        else{
            setLoading(false);
        }

    }

    const onSuccess = (response)=> {
        setLoading(false);

        const {data} = response;
        if(data.status){
          
            setCategory("");
            setfile("");
            handleClose();
            props.onRefresh();
        }
        
    }

    const onError = (error) => {
        setLoading(false);
        alert(error.toString());
    }

    const handleClose = () => {
      
        setCategoryHelper("");
        setfileHelper("");
        props.handleClose();

    }

    const handleImageChange = (e) => {
      const file=e.target.files[0];
      
      const reader = new FileReader();
      console.log("next");
        
      reader.onload = function () {
          base64String = reader.result.replace("data:", "")
              .replace(/^.+,/, "");
    
          // imageBase64Stringsep = base64String;
        console.log(base64String)
          // alert(imageBase64Stringsep);
         setfile(base64String);
      }
      reader.readAsDataURL(file);
      // setfile(e[0].base64);

      // let newData= e.target.files[0];
      // setfile(newData);
  };

  let base64String = "";

  
  return (
    <div>
      
      <Dialog open={props.open} onClose={handleClose}
      maxWidth={maxWidth}
      fullWidth={fullWidth}
        TransitionComponent={Transition}
      >
        <DialogTitle>Edit Category</DialogTitle>
        <DialogContent>
          
    
         <Box sx={{display:"flex", gap:'10px'}} >

         <TextField
            autoFocus
            margin="dense"
            id="name"
            label="Category Name"
            type="text"
            error={categoryHelper !== "" }
            helperText={categoryHelper}
            value={category}
            onChange={(e) => setCategory(e.target.value)}
            variant="standard"
          />
        </Box>
        <DialogContentText sx={{mt:5,mb:3}}>
            Please Uploade an image of 500 x 500px
          </DialogContentText>
          {/* <img width={100} height={100} src={host + file}/> */}
        <input type="file" name="" id="fileId" 
        helperText={fileHelper}
        onChange={(e) => {handleImageChange(e)}} />



        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button disabled={loading}  onClick={() => saveAccount() }>Save</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}


