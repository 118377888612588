// components
import SvgIconStyle from '../../../components/SvgIconStyle';

// ----------------------------------------------------------------------

const getIcon = (name) => <SvgIconStyle src={`/icons/${name}.svg`} sx={{ width: 1, height: 1 }} />;

const ICONS = {
  user: getIcon('ic_user'),
  ecommerce: getIcon('ic_ecommerce'),
  analytics: getIcon('ic_analytics'),
  dashboard: getIcon('ic_dashboard'),
  volunteer:getIcon('volunteer'),
  job:getIcon('job'),
  activity:getIcon('activity'),
  event:getIcon('event'),
  daawah:getIcon('daawah'),
  madrasa:getIcon('madrasa'),
  networking:getIcon('networking'),
  islamiclearning:getIcon('videos')
};

const sidebarConfig = [
  // GENERAL
  // ----------------------------------------------------------------------
  {
    subheader: 'Super Admin',
    items: [
      { title: 'Dashboard', path: '/superadmin/dashboard', icon: ICONS.analytics },
    ],
    
  },
  {
    subheader: 'Masjid',
    items: [
      { title: 'Add Masjid', path: '/superadmin/madrasas/addmasjid',icon: ICONS.madrasa},
    ],
    
  },
  {
    subheader: 'Madrasas',
    items: [
      { title: 'accounts', path: '/superadmin/madrasas/accounts',icon: ICONS.user },
      { title: 'madrasas', path: '/superadmin/madrasas/madrasas',icon: ICONS.madrasa },
    ],
    
  },
  {
    subheader: 'Services',
    items: [
      {
        title: 'Volunteer',
        path: '/superadmin/volunteer',
        icon: ICONS.volunteer,
        children: [
          { title: 'Volunteer', path: '/superadmin/volunteer/volunteer' },
          { title: 'Volunteer Categories', path: '/superadmin/volunteer/volunteercategories' },
        ],
      },
      {
        title: 'Job',
        path: '/superadmin/job',
        icon: ICONS.job,
        children: [
          { title: 'Job', path: '/superadmin/job/job' },
          { title: 'Job Categories', path: '/superadmin/job/jobcategories' },
        ],
      },
      {
        title: 'Dawaah',
        path: '/superadmin/dawaah',
        icon: ICONS.daawah,
        children: [
          { title: 'Dawaah', path: '/superadmin/dawaah/dawaah' },
          { title: 'Dawaah Categories', path: '/superadmin/dawaah/dawaahcategories' },
        ],
      },
      {
        title: 'Activity',
        path: '/superadmin/activity',
        icon: ICONS.activity,
        children: [
          { title: 'Activity', path: '/superadmin/activity/activity' },
          { title: 'Activity Categories', path: '/superadmin/activity/activitycategories' },
        ],
      },
      {
        title: 'Event',
        path: '/superadmin/event',
        icon: ICONS.event,
        children: [
          { title: 'Event', path: '/superadmin/event/event' },
          { title: 'Event Categories', path: '/superadmin/event/eventcategories' },
        ],
      },
      {
        title: 'networking',
        path: '/superadmin/networking',
        icon: ICONS.networking,
        children: [
          { title: 'networking', path: '/superadmin/networking/networking' },
          { title: 'networking Categories', path: '/superadmin/networking/networkingcategories' },
        ],
      },
      { title: 'Umrah & Hajj', path: '/superadmin/umrahandhajj', icon: ICONS.dashboard },
      { title: 'Faq\'s', path: '/superadmin/faqs', icon: ICONS.dashboard },
      { title: 'Islamic Learning', path: '/superadmin/IslamicLearning', icon: ICONS.islamiclearning },

    ],
    
  },
];

export default sidebarConfig;
