/* eslint-disable */
import React, {useState} from 'react'
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import {Box} from "@mui/material";
import Slide from '@mui/material/Slide';
import { postRequest } from '../../../utils/NetworkHandler';
// import FileBase64 from 'react-file-base64';
import PhoneInput from "react-phone-input-2";
import Rating from '@mui/material/Rating';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import { Check } from '@mui/icons-material';


const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="left" ref={ref} {...props} />;
});


export default function AddVendorDialog(props) {
    const [maxWidth, setMaxWidth] = React.useState('xs');
    const [fullWidth, setFullWidth] = React.useState(true);
    const [loading, setLoading] = useState(false);

    const [name, setname] = useState("");
    const [description, setdescription] = useState("");
    const [logo, setlogo] = useState("");
    const [thumbnail, setthumbnail] = useState("");
    const [state, setstate] = useState("");
    const [country, setcountry] = useState("");
    const [address, setaddress] = useState("");
    const [facebook, setfacebook] = useState("");
    const [Instagram, setInstagram] = useState("");
    const [website, setwebsite] = useState("");
    const [rating, setrating] = useState("");
    const [hajj, sethajj] = useState("");
    const [umrah, setumrah] = useState("");
    const [contact, setcontact] = useState("");



    const [nameHelper, setnameHelper] = useState("");
    const [descriptionHelper, setdescriptionHelper] = useState("");
    const [logoHelper, setlogoHelper] = useState("");
    const [thumbnailHelper, setthumbnailHelper] = useState("");
    const [stateHelper, setstateHelper] = useState("");
    const [countryHelper, setcountryHelper] = useState("");
    const [addressHelper, setaddressHelper] = useState("");
    const [facebookHelper, setfacebookHelper] = useState("");
    const [InstagramHelper, setInstagramHelper] = useState("");
    const [websiteHelper, setwebsiteHelper] = useState("");
    const [ratingHelper, setratingHelper] = useState("");
    const [hajjHelper, sethajjHelper] = useState("");
    const [umrahHelper, setumrahHelper] = useState("");
    let requiredText = "This field is required";


    const saveAccount = () => {
        setLoading(true);
        let formValidated = true;
        if(name === ""){
            formValidated = false;
            setnameHelper(requiredText);
        }
        if(logo === ""){
            formValidated = false;
            setlogoHelper(requiredText);
        }
        if(formValidated){
          
            var data = {'name': name, 'logo': logo,"description":description,"thumbnail":thumbnail,"state":state,"country":country,"contact_number":contact,"complete_location":address,"facebook":facebook,"instagram":Instagram,"Website":website,"rating_star":rating,"hajj":hajj,"Umrah":umrah}
            console.log(data);
            postRequest("/umrahandhajj/api/addvendor", data, onSuccess, onError, true);
        }
        else{
            setLoading(false);
        }

    }

    const onSuccess = (response)=> {
        setLoading(false);

        var data = response.data;
        if(data.status){
            setname("");
            setlogo("");
            handleClose();
            props.onRefresh();
        }
        else{
            let message = data.message;
            setnameHelper(message);
        }
    }

    const onError = (error) => {
        setLoading(false);
        alert(error.toString());
    }

    const handleClose = () => {
        setnameHelper("");
        setlogoHelper("");
        props.handleClose();

    }

    const handlelogochange = (e) => {
      var file=e.target.files[0];
      
      var reader = new FileReader();
      console.log("next");
        
      reader.onload = function () {
          base64String = reader.result.replace("data:", "")
              .replace(/^.+,/, "");
    
          // imageBase64Stringsep = base64String;
    
          // alert(imageBase64Stringsep);
         setlogo(base64String);
      }
      reader.readAsDataURL(file);
      // setfile(e[0].base64);

      // let newData= e.target.files[0];
      // setfile(newData);
  };
  const handleUmrahchange=(e)=>{
    setumrah("True")
  }
  const handleHajjchange=(e)=>{
    setumrah("True")
  }
  const handlethumbnailchange = (e) => {
    var file=e.target.files[0];
    
    var reader = new FileReader();
    console.log("next");
      
    reader.onload = function () {
        base64String = reader.result.replace("data:", "")
            .replace(/^.+,/, "");
  
        // imageBase64Stringsep = base64String;
  
        // alert(imageBase64Stringsep);
       setthumbnail(base64String);
    }
    reader.readAsDataURL(file);
    // setfile(e[0].base64);

    // let newData= e.target.files[0];
    // setfile(newData);
};

  let base64String = "";
   
  

  return (
    <div>
      
      <Dialog open={props.open} onClose={handleClose}
      maxWidth={maxWidth}
      fullWidth={fullWidth}
        TransitionComponent={Transition}
      >
        <DialogTitle>Add vendor</DialogTitle>
        <DialogContent>
        <p>Contact Number</p>
        <PhoneInput
        fullWidth
            country={"fi"}
            enableSearch={true}
            onChange={(value) => setcontact("+" + value)}
          />  
         <Box sx={{display:"flex", gap:'10px'}} >
         <TextField
         fullWidth
            error={nameHelper !== "" ? true: false }
            helperText={nameHelper}
            value={name}
            onChange={(e) => setname(e.target.value)}
            autoFocus
            margin="dense"
            id="Category"
            label="Company Name"
            type="text"
            variant="standard"
          />
         </Box>
         <Box sx={{display:"flex", gap:'10px'}} >
          <TextField
          fullWidth
          rows={2}
          multiline
            error={descriptionHelper !== "" ? true: false }
            helperText={descriptionHelper}
            value={description}
            onChange={(e) => setdescription(e.target.value)}
            autoFocus
            margin="dense"
            id="Category"
            label="Description"
            type="text"
            variant="standard"
          />
         </Box>
         <Box sx={{display:"flex", gap:'10px'}} >
         <TextField
         fullWidth
            error={stateHelper !== "" ? true: false }
            helperText={stateHelper}
            value={state}
            onChange={(e) => setstate(e.target.value)}
            autoFocus
            margin="dense"
            id="Category"
            label="State"
            type="text"
            variant="standard"
          />
          <TextField
         fullWidth
            error={countryHelper !== "" ? true: false }
            helperText={countryHelper}
            value={country}
            onChange={(e) => setcountry(e.target.value)}
            autoFocus
            margin="dense"
            id="Category"
            label="Country"
            type="text"
            variant="standard"
          />
         </Box>
         <Box sx={{display:"flex", gap:'10px'}} >
          <TextField
          fullWidth
          rows={2}
          multiline
            error={addressHelper !== "" ? true: false }
            helperText={addressHelper}
            value={address}
            onChange={(e) => setaddress(e.target.value)}
            autoFocus
            margin="dense"
            id="Category"
            label="Complete Address"
            type="text"
            variant="standard"
          />
         </Box>
         <Box sx={{display:"flex", gap:'10px'}} >
          <TextField
          fullWidth
        //   rows={2}
        //   multiline
            error={facebookHelper !== "" ? true: false }
            helperText={facebookHelper}
            value={facebook}
            onChange={(e) => setfacebook(e.target.value)}
            autoFocus
            margin="dense"
            id="Category"
            label="Facebook"
            type="text"
            variant="standard"
          />
         </Box>
         <Box sx={{display:"flex", gap:'10px'}} >
          <TextField
          fullWidth
        //   rows={2}
        //   multiline
            error={InstagramHelper !== "" ? true: false }
            helperText={InstagramHelper}
            value={Instagram}
            onChange={(e) => setInstagram(e.target.value)}
            autoFocus
            margin="dense"
            id="Category"
            label="Instagram"
            type="text"
            variant="standard"
          />
         </Box>
         <Box sx={{display:"flex", gap:'10px'}} >
          <TextField
          fullWidth
        //   rows={2}
        //   multiline
            error={websiteHelper !== "" ? true: false }
            helperText={websiteHelper}
            value={website}
            onChange={(e) => setwebsite(e.target.value)}
            autoFocus
            margin="dense"
            id="Category"
            label="Website"
            type="text"
            variant="standard"
          />
         </Box>

         {/* <DialogContentText sx={{mt:5,mb:3}}>
            Logo
          </DialogContentText> */}
          <p>Logo</p>  
         <Box sx={{display:"flex" , gap:'10px',mt:0,mb:3}} >

         <input label="logo" type="file" name="" id="fileId" 
        onChange={(e) => {handlelogochange(e)}}></input>
          
        </Box>
        <p>Thumbnail</p>  
       <Box sx={{display:"flex" , gap:'10px',mt:0,mb:3}} >


       <input label="logo" type="file" name="" id="fileId" 
onChange={(e) => {handlethumbnailchange(e)}}></input>
 
</Box>

  <p>Rating</p>  
<Rating
  name="simple-controlled"
  value={rating}
  onChange={(event, newValue) => {
    setrating(newValue);
  }}
/>
    <Box>
    <FormGroup>
  <FormControlLabel control={<Checkbox onChange={ (e) => setumrah(e.target.checked)} defaultChecked />} label="Umrah" />
  <FormControlLabel control={<Checkbox onChange={ (e) => sethajj(e.target.checked)} defaultChecked />} label="Hajj" />
</FormGroup>
    </Box>

        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button disabled={loading}  onClick={() => saveAccount() }>Save</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}


