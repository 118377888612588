/* eslint-disable */
import React, {useEffect, useState} from 'react'
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import {Box} from "@mui/material";
import Slide from '@mui/material/Slide';
// import BatchCheckbox from '../components/BatchCheckbox';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { postRequest } from '../../utils/NetworkHandler';
import EditBatch from '../../components/EditBatch';

const Transition = React.forwardRef((props, ref) => <Slide direction="left" ref={ref} {...props} />);


export default function EditMadrasaDialog(props) {
    const [maxWidth, setMaxWidth] = React.useState('xs');
    const [fullWidth, setFullWidth] = React.useState(true);
    const [loading, setLoading] = useState(false);

    const [fullName, setFullName] = useState("");
    const [desc, setDescName]=useState("");
    const [fee, setFee] = useState("");
    const [address, setAddress] = useState("");

    const [fullNameHelper, setFullNameHelper] = useState("");
    const [descHelper, setDescHelper]=useState("");
    const [feeHelper, setFeeHelper] = useState("");
    const [addressHelper, setAddressHelper] = useState("");
    const [SampleData, setSampleData] = useState([]);

    const {batches} = props;

    const [batchData, setBatchData] = useState([]);

    function createBatchdata(id, checked, batch){
      return {id, checked, batch};
    }

    const {editObj} = props;

    useEffect( () => {
      if(editObj !== null && editObj !== undefined){
        const madrasa_name = editObj.name;
        const madrasa_desc=editObj.describtion;
        const madrasa_fee = editObj.fee;
        const madrasa_address = editObj.address;

        const madrasa_batches = editObj.batches;

        const batchArr = [];
        for(let i =0; i< batches.length; i++){
          const batch = batches[i];
          let found = false;
          for(let j =0; j< madrasa_batches.length; j++){
            const selected_batch = madrasa_batches[j];
            if (selected_batch.id === batch.id){
              found = true;
            }
          }

          const data = createBatchdata(batch.id, found, batch.batch);
          batchArr.push(data);
        }
        setBatchData(batchArr);
        setDescName(madrasa_desc);
        setFullName(madrasa_name);
        setFee(madrasa_fee);
        setAddress(madrasa_address);
      }
    }, [editObj,] )
    
      // console.log(desc);
   

    const requiredText = "This field is required";

    const handleBatchCheckChange = (index, event) => {
      const data = [...batchData];
      const editRow = data[index];
      editRow.checked = event.target.checked;
      data[index] = editRow;
      setBatchData(data);

    }


    const saveAccount = () => {
        setLoading(true);
        let formValidated = true;
       
        if(fullName === ""){
            formValidated = false;
            setFullNameHelper(requiredText);
        }
        if(desc === ""){
          formValidated = false;
          setDescHelper(requiredText);
      }
        if(address === ""){
          formValidated = false;
          setAddressHelper(requiredText);
        }
        
       
        if(isNaN(fee) || fee === "" ){
          formValidated = false;
          setFeeHelper("Please eneter a valid number");
        }
        
        let checked = false;
        for(let i =0; i< batchData.length; i++){
          const batch = batchData[i];
          if(batch.checked){
            checked = true;
          }
        }

        if (!checked){
          formValidated = false;
          alert("Please select at least one batch");
        }
        if(SampleData.length==="0"){
          alert("no batch selected")
          setSampleData(batchData)
          console.log(batchData);
        }
    
        if(formValidated){
            const data = { 'edit_id': editObj.id, 'descripton':desc, 'madrasa_name': fullName, 'fee': fee, 'address': address, 'batches': SampleData}
            postRequest("/landing/api/ummah/edit-madrasa", data, onSuccess, onError, true);
        }
        else{
            setLoading(false);
        }

    }

    const onSuccess = (response)=> {
        setLoading(false);

        const {data} = response;
        if(data.status){
          
            setFullName("");
            setDescName("")
            setAddress("");
            setFee("");
            handleClose();
            props.onRefresh();
        }
        else{
           
        }
    }

    const onError = (error) => {
        setLoading(false);
        alert(error.toString());
    }

    const handleClose = () => {
      
        setFullNameHelper("");
        setFeeHelper("");
        setAddressHelper("");
        props.handleClose();

    }

  
  return (
    <div>
      
      <Dialog open={props.open} onClose={handleClose}
      maxWidth={maxWidth}
      fullWidth={fullWidth}
        TransitionComponent={Transition}
      >
        <DialogTitle>Edit madrasa</DialogTitle>
        <DialogContent>
          
    
         <Box sx={{display:"flex", gap:'10px'}} >

         <TextField
            autoFocus
            margin="dense"
            id="name"
            label="Madrasa Name"
            type="text"
            error={fullNameHelper !== "" }
            helperText={fullNameHelper}
            value={fullName}
            onChange={(e) => setFullName(e.target.value)}
            variant="standard"
          />

<TextField
            autoFocus
            margin="dense"
            id="fee"
            label="Madrasa Fee"
            type="number"
            error={feeHelper !== "" }
            helperText={feeHelper}
            value={fee}
            onChange={(e) => setFee(e.target.value)}
            variant="standard"
          />
         
        </Box>

        <Box sx={{display:"flex", gap:'10px'}} >
        <TextField
            autoFocus
            margin="dense"
            id="addresss"
            label="Address"
            type="text"
            multiline
            error={addressHelper !== "" }
            helperText={addressHelper}
            value={address}
          rows={4}

            onChange={(e) => setAddress(e.target.value)}
            variant="standard"
          />

<TextField
            rows={4}
            multiline
            autoFocus
            margin="dense"
            id="desc"
            label="Description"
            type="text"
            error={descHelper !== "" }
            helperText={descHelper}
            value={desc}
            onChange={(e) => setDescName(e.target.value)}
            variant="standard"
          />
 </Box>

      <EditBatch batchData = {batchData} setSampleData={setSampleData}/>

   

        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button disabled={loading}  onClick={() => saveAccount() }>Save</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}


