import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
// @mui
import { styled } from '@mui/material/styles';
import { Box, Link, Typography, Avatar } from '@mui/material';
import { getRequest } from '../../../utils/NetworkHandler';
import logo from '../../../logo.png';

// ----------------------------------------------------------------------

const RootStyle = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(2, 2.5),
  borderRadius: Number(theme.shape.borderRadius) * 1.5,
  backgroundColor: theme.palette.grey[500_12],
  transition: theme.transitions.create('opacity', {
    duration: theme.transitions.duration.shorter,
  }),
}));

// ----------------------------------------------------------------------

NavbarAccount.propTypes = {
  isCollapse: PropTypes.bool,
};

export default function NavbarAccount({ isCollapse }) {
  useEffect(() => {
    getData();
  }, []);

  const onRefresh = () => {

    getData();
    
  };

  const [username, setusername] = useState([]);
  const [email, setemail] = useState([]);
  const [name,setname]=useState([]);
  const [usertype,setUsertype]=useState([]);

  const onSuccess = (response) => {
    // console.log(response.data)
    const {data} = response;

    setusername(data.username);
    setemail(data.email);
    setname(data.name);
    setUsertype(data.usertype);
  };
   
  const onthisError = (error) => {
    alert(error.toString());
  };

  const getData = () => {
    getRequest(
      "/landing/api/ummah/getuser",
      null,
      onSuccess,
      onthisError,
      true
    );
   
  };



  return (
    <Link underline="none" color="inherit">
      <RootStyle
        sx={{
          ...(isCollapse && {
            bgcolor: 'transparent',
          }),
        }}
      >
        <Avatar src={logo} width="50px" heigth="50px" alt="Rayan Moran" />

        <Box
          sx={{
            ml: 2,
            transition: (theme) =>
              theme.transitions.create('width', {
                duration: theme.transitions.duration.shorter,
              }),
            ...(isCollapse && {
              ml: 0,
              width: 0,
            }),
          }}
        >
          <Typography variant="subtitle2" noWrap style={{textTransform: 'capitalize',fontSize:'10px',}}>
          {username}
          </Typography> 
          <Typography variant="body2" noWrap sx={{ color: 'text.secondary' }}>
          {usertype}
          </Typography>
        </Box>
      </RootStyle>
    </Link>
  );
}
