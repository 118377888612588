/* eslint-disable */
import React, {useEffect, useState} from 'react'
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import {Box} from "@mui/material";
import Slide from '@mui/material/Slide';
import { postRequest } from '../../../utils/NetworkHandler';

import EditBatch from '../../../components/EditBatch';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="left" ref={ref} {...props} />;
});


export default function EditMadrasaDialog(props) {
    const [maxWidth, setMaxWidth] = React.useState('xs');
    const [fullWidth, setFullWidth] = React.useState(true);
    const [loading, setLoading] = useState(false);

    const [fullName, setFullName] = useState("");
    const [desc, setDescName]=useState("");
    const [fee, setFee] = useState("");
    const [address, setAddress] = useState("");

    const [fullNameHelper, setFullNameHelper] = useState("");
    const [descHelper, setDescHelper]=useState("");
    const [feeHelper, setFeeHelper] = useState("");
    const [addressHelper, setAddressHelper] = useState("");
    const [SampleData, setSampleData] = useState([]);

    const batches = props.batches;

    const [batchData, setBatchData] = useState([]);

    function createBatchdata(id, checked, batch){
      return {id, checked, batch};
    }
    


    const editObj  = props.editObj;

    useEffect( () => {
      if(editObj !== null && editObj !== undefined){
        // setSampleData([])
        // batchData.map((data,index)=>{
        //   // console.log(data.checked)
        //   if(data.checked===true)
        //   {
        //      SampleData.push(data)
        //   }
        //  })

        let madrasa_name = editObj.name;
        let madrasa_desc=editObj.describtion;
        let madrasa_fee = editObj.fee;
        let madrasa_address = editObj.address;

        let madrasa_batches = editObj.batches;

        var batchArr = [];
        for(let i =0; i< batches.length; i++){
          let batch = batches[i];
          let found = false;
          for(let j =0; j< madrasa_batches.length; j++){
            let selected_batch = madrasa_batches[j];
            if (selected_batch.id === batch.id){
              found = true;
            }
          }

          let data = createBatchdata(batch.id, found, batch.batch);
          batchArr.push(data);
        }
        setBatchData(batchArr);
        setDescName(madrasa_desc);
        setFullName(madrasa_name);
        setFee(madrasa_fee);
        setAddress(madrasa_address);
      }
    }, [editObj,] )
    

   

    let requiredText = "This field is required";

    const handleBatchCheckChange = (index, event) => {
      let data = [...batchData];
      let editRow = data[index];
      editRow.checked = event.target.checked;
      data[index] = editRow;
      setBatchData(data);

    }


    const saveAccount = () => {
        setLoading(true);
        let formValidated = true;
       
        if(fullName === ""){
            formValidated = false;
            setFullNameHelper(requiredText);
        }
        if(desc === ""){
          formValidated = false;
          setDescHelper(requiredText);
      }
        if(address === ""){
          formValidated = false;
          setAddressHelper(requiredText);
        }
        
       
        if(isNaN(fee) || fee === "" ){
          formValidated = false;
          setFeeHelper("Please eneter a valid number");
        }
        console.log(SampleData.length)
        console.log(SampleData)
        console.log(batchData)
        if(SampleData.length==="0"){
          alert("no batch selected")
          setSampleData(batchData)
          console.log(batchData);
        }
        // let checked = false;
        // for(let i =0; i< batchData.length; i++){
        //   let batch = batchData[i];
        //   if(batch.checked){
        //     checked = true;
        //   }
        // }

        // if (!checked){
        //   formValidated = false;
        //   alert("Please select at least one batch");
        // }

    
        if(formValidated){
            var data = { 'edit_id': editObj.id, 'madrasa_name': fullName,'descripton':desc, 'fee': fee, 'address': address, 'batches': SampleData}
            postRequest("/landing/api/edit-madrasa", data, onSuccess, onError, true);
        }
        else{
            setLoading(false);
        }

    }

    const onSuccess = (response)=> {
        setLoading(false);
        console.log("Sucess")
        var data = response.data;
        if(data.status){
          
            setFullName("");
            setAddress("");
            setFee("");
            handleClose();
            props.onRefresh();
            setSampleData("");  
        }
        else{
           
        }
    }

    const onError = (error) => {
        setLoading(false);
        alert(error.toString());
    }

    const handleClose = () => {
      
        setFullNameHelper("");
        setFeeHelper("");
        setAddressHelper("");
        props.handleClose();

    }

  
  return (
    <div>
      
      <Dialog open={props.open} onClose={handleClose}
      maxWidth={maxWidth}
      fullWidth={fullWidth}
        TransitionComponent={Transition}
      >
        <DialogTitle>Edit madrasa</DialogTitle>
        <DialogContent>
          
    
         <Box sx={{display:"flex", gap:'10px'}} >

         <TextField 
            margin="dense"
            id="name"
            label="Madrasa Name"
            type="text"
            error={fullNameHelper !== "" ? true: false }
            helperText={fullNameHelper}
            value={fullName}
            onChange={(e) => setFullName(e.target.value)}
            variant="standard"
          />

<TextField
            margin="dense"
            id="fee"
            label="Madrasa Fee"
            type="number"
            error={feeHelper !== "" ? true: false }
            helperText={feeHelper}
            value={fee}
            onChange={(e) => setFee(e.target.value)}
            variant="standard"
          />
         
        </Box>


        <Box sx={{display:"flex", gap:'10px'}} >
        <TextField  
            margin="dense"
            id="addresss"
            label="Address"
            type="text"
            multiline
            error={addressHelper !== "" ? true: false }
            helperText={addressHelper}
            value={address}
          rows={2}

            onChange={(e) => setAddress(e.target.value)}
            variant="standard"
          />

<TextField
            rows={2}
            multiline
            margin="dense"
            id="desc"
            label="Description"
            type="text"
            error={descHelper !== "" ? true: false }
            helperText={descHelper}
            value={desc}
            onChange={(e) => setDescName(e.target.value)}
            variant="standard"
          />
          </Box>
          {/* <BatchCheckbox 
          batchData = {batchData}
          handleChange = {handleBatchCheckChange}
          /> */}
          <EditBatch batchData = {batchData} setSampleData={setSampleData}/>
   {/* <Batch/> */}

        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button disabled={loading}  onClick={() => saveAccount() }>Save</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}


