/* eslint-disable camelcase */
import React, {useEffect, useState} from "react";
import {Box, Card, Typography, CardContent, CardActions, Button, TextField} from "@mui/material";
// import LoginIcon from '@mui/icons-material/Login';
import {useNavigate} from "react-router-dom";
import CircularProgress from '@mui/material/CircularProgress';
import Logo from "./logo.png";
import { apptokenKey , postRequest } from "./utils/NetworkHandler";

 export default function Login(){
    const navigate = useNavigate();
    const [loggedIn, setLogggedIn] = useState(true);
    useEffect( () => {

        const appToken  = localStorage.getItem(apptokenKey);
        if (appToken !== null && appToken !== ""){
            navigate(
                ""
            );
            setLogggedIn(true);
        }
        else{
            setLogggedIn(false);
        }
    }, [] );

    const onSuccess = (response) => {
        const {data} = response;
        if (data.status){
            const {token} = data;
            localStorage.setItem(apptokenKey, token);
            console.log(data)

            // eslint-disable-next-line camelcase
            const is_admin = data.is_ummah_admin;
            const is_masjid=data.is_masjid_admin;

            if (is_admin){
                navigate("/admin");
            }
            else if(is_masjid)
            {
                navigate("/masjidadmin");
            }
            else{
                navigate("/superadmin");
            }
           
          }
          else{
            setLogggedIn(false);
            alert(data.message);
        }
    }

    const onError = (response) => {
        setLogggedIn(false);
        alert("Something went wrong, please try again");
    }


   const loginRequest =  () => {
    setLogggedIn(true);
    const data = {"username": username, "password": password}
    postRequest("/landing/api/login", data, onSuccess, onError, false);
    }

    const [username, setUserName] = useState("");
    const [password, setPassword] = useState("");

    return (

     <div>
        {loggedIn ? 
   <Box
   sx={{display:'flex', justifyContent:'center', alignItems:'center', height:'100vh'}}
   bgcolor="primary.main"
   >
    <CircularProgress color="secondary" />
    </Box>
        :
        <Box
     sx={{display:'flex', justifyContent:'center', alignItems:'center', height:'100vh'}}
     bgcolor="primary.main"
     >
        <Card>
        <CardContent>
        
        
        <Box
        sx={{display:'flex', justifyContent:'center', alignItems:'center'}}
        >
        <img  
        width={100}
        src={Logo} alt="logo" />
        </Box>

       <Box sx={{display:'block'}}>
       <TextField
        sx={{minWidth:'300px', mt:2}}
        value={username}
        onChange={ (e) => {setUserName(e.target.value)} }
        id="outlined-basic" color="primary" label="Username" variant="outlined" />

       
       </Box>
       <Box sx={{display:'block'}}>

       <TextField
        sx={{minWidth:'300px', mt:2}}
        id="outlined-basic" color="primary" label="Password"
        type="password"
        value={password}
        onChange= {(e) => {setPassword(e.target.value)}}
        variant="outlined" />
       </Box>

        <Box
        sx={{display:'flex', justifyContent:'center', alignItems:'center', mt:2}}
        >

        <Button size="large" color="primary" variant="contained" 
        onClick={() => loginRequest()}
        // startIcon={<LoginIcon/>} 
        >
        Login</Button>


        </Box>

      </CardContent>
     
        </Card>

     </Box>
    }
     </div>

    )
}