/* eslint-disable */
import React, {useState, useEffect} from 'react'
import Fab from '@mui/material/Fab';
import AddIcon from '@mui/icons-material/Add';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import {IconButton, Rating, Typography} from "@mui/material"; 
import {host,getRequest} from "../../../utils/NetworkHandler";
import EditIcon from '@mui/icons-material/Edit';
// import EditCategoryDialog from './EditCategoryDialog';
import AddVendorDialog from './AddVendorDialog';


export default function UmrahAndHajj() {

  const onRefresh = () => {
    getData();
  };

  useEffect( () => {
    getData();
  },  [])

  const handleAddAccountClose = () =>{
    setaddCategoryOpen(false);
  }

  const handleResetPasswordClose = () =>{
    setCategoryOpen(false);
  }

  const [category, setCategory] = useState([]);

  const onSuccess = (response) => {
    // console.log(response.data)
    var data=response.data
    setCategory(data)
  }

  const onError = (error) => {
    alert(error.toString())
  }

  const getData = () => {
      getRequest("/umrahandhajj/api/getall", null, onSuccess, onError, true);
  }

   
  const [addCategoryOpen, setaddCategoryOpen] = useState(false);
  const [editCategoryOpen, setCategoryOpen] = useState(false);
  const [categoryEdit, setCategoryEdit] = useState("-1");
  return (
    <div>
      <AddVendorDialog
      open={addCategoryOpen}
      handleClose={handleAddAccountClose}
      onRefresh = {onRefresh}
      />
        {/* <EditCategoryDialog
      open={editCategoryOpen}
      handleClose={handleResetPasswordClose}
      editobj={categoryEdit}
      onRefresh = {onRefresh}
/> */}
      <Typography component="div" variant="h5" sx={{fontSize:18, pb:2}} >Umrah & Hajj</Typography>
      <TableContainer component={Paper} sx={{mb:10}} >
      <Table aria-label="Accounts">
        <TableHead>
          <TableRow>
            <TableCell align="left">Company Name</TableCell>
            <TableCell align="left">Description</TableCell>
            <TableCell align="right">Logo</TableCell>
            <TableCell align="right">Thumbnail</TableCell>
            
            <TableCell align="right">Address</TableCell>
            <TableCell align="right">Rating</TableCell>
            <TableCell align="right">Edit</TableCell>
          
          </TableRow>
        </TableHead>
        <TableBody>
          {category.map((row, index) => (
            <TableRow
              key={index}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell align='left'>  
                {row.name}
              </TableCell>
              <TableCell align='left'>  
                {row.description}
              </TableCell>
              <TableCell align='left'><img width={100} height={100} src={host + row.logo}/></TableCell>
              <TableCell align='left'><img width={100} height={100} src={host + row.thumbnail}/></TableCell>
            
             
              <TableCell align='left'>  
                {row.complete_location}
              </TableCell>
              <TableCell> 
              <Rating name="read-only" value={row.rating_star} readOnly />
             </TableCell>
              <TableCell align="right">
                <IconButton 
                onClick={() => {
                  setCategoryOpen(true);
                  setCategoryEdit(row);
                }}
                >
                <EditIcon />
                </IconButton></TableCell>
             
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>

<div
className='fab-container'
>
<Fab variant="extended" color='primary' 
onClick={() => setaddCategoryOpen(true)}
>
  <AddIcon sx={{ mr: 1 }} />
  Add Category
</Fab>
</div>
    </div>
  )
}
