import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import ReactStars from "react-rating-stars-component";

export default function ShowFeedBackDialog(props) {
    console.log(props)
  const [open, setOpen] = React.useState(props.open);
  const [scroll, setScroll] = React.useState('paper');
  const [studentdata,setStudentdata]=React.useState(props.studentdata)

  console.log(studentdata)

  const feedbacklist = () => {
    if (studentdata === "") {
      console.log("studentdata is null");
    } else {
      if (studentdata.feedbacks.length === 0) {
        return <p>No feedbacks yet</p>;
      } 
        return studentdata.feedbacks.map((feedback, index) => (
            <>
            <Card key={index} sx={{ minWidth: 275 }}>
                <CardContent>
                    <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                        Students Study Rating
                    </Typography>
                    <Typography sx={{ mb: 1.5 }} color="text.secondary">
                    <ReactStars
                        size={30}
                        value={feedback.study_rating}
                        edit={false}
                        />

                    </Typography>
                    <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                        Parents experience
                    </Typography>
                    <Typography sx={{ mb: 1.5 }} color="text.secondary">
                    <ReactStars
                        size={30}
                        value={feedback.experience_rating}
                        edit={false}
                        />

                    </Typography>
                    <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                        Comment
                    </Typography>
                    <Typography sx={{ mb: 1.5 }} color="text.secondary">
                    {feedback.comment}
                    </Typography>
                    <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                    Date posted: {feedback.date_created}
                    </Typography>
                </CardContent>
            </Card><br /></>
        )).reverse();
      
    }
  };
  

  if (studentdata === "") {
    console.log("studentdata is null");
  } else {
    studentdata.feedbacks.map((feedback, index) => (
      console.log(feedback.comment)
    ));
  }
  

  const handleClose = () => {
    setOpen(false);
  };

  const descriptionElementRef = React.useRef(null);
  React.useEffect(() => {
    if (open) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [open]);

  return (
    <div>
      <Dialog
        open={open}
        scroll={scroll}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
      >
        <DialogTitle id="scroll-dialog-title">Parents Feedbacks</DialogTitle>
        <DialogContent dividers={scroll === 'paper'}>
          <DialogContentText
            id="scroll-dialog-description"
            ref={descriptionElementRef}
            tabIndex={-1}
          >
         {feedbacklist()}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}