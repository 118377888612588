/* eslint-disable */
import React, {useState, useEffect} from 'react'
import Box from '@mui/material/Box';
import Fab from '@mui/material/Fab';
import AddIcon from '@mui/icons-material/Add';

import RotateLeftIcon from '@mui/icons-material/RotateLeft';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import {IconButton, Typography} from "@mui/material"; 
import AddAccountDialog from './AddAccountDialog';
import {getRequest, postRequest} from "../../../utils/NetworkHandler";
import ResetPasswordDialog from "./ResetPasswordDialog";
// @mui
import { Container} from '@mui/material';
// hooks
import useSettings from '../../../hooks/useSettings';
// components
import Page from '../../../components/Page';


export default function Home() {

  const { themeStretch } = useSettings();

  useEffect( () => {
    getData();
  },  [])

  const handleAddAccountClose = () =>{
    setAddAccountOpen(false);
  }

  const handleResetPasswordClose = () =>{
    setResetPasswordOpen(false);
  }

  const onRefresh = () => {
    getData();
  }
  const [accounts, setAccounts] = useState([]);

  const onSuccess = (response) => {
    var data = response.data;
    setAccounts(data.accounts);
  }

  const onError = (error) => {
    alert(error.toString())
  }

  const getData = () => {
      getRequest("/landing/api/get-accounts", null, onSuccess, onError, true);
  }

   
  const [addAccountOpen, setAddAccountOpen] = useState(false);
  const [resetPasswordOpen, setResetPasswordOpen] = useState(false);
  const [resetId, setResetId] = useState("-1");
  return (
    <Page title="Accounts">
      <Container maxWidth={themeStretch ? false : 'xl'}>
    <div>
      <AddAccountDialog
      open={addAccountOpen}
      handleClose={handleAddAccountClose}
      onRefresh = {onRefresh}
      />
        <ResetPasswordDialog
      open={resetPasswordOpen}
      handleClose={handleResetPasswordClose}
      accountID={resetId}
/>
      <Typography component="div" variant="h5" sx={{fontSize:18, pb:2}} >Accounts</Typography>
      <TableContainer component={Paper} sx={{mb:10}} >
      <Table aria-label="Accounts">
        <TableHead>
          <TableRow>
            <TableCell>Username</TableCell>
            <TableCell align="right">Name</TableCell>
            <TableCell align="right">Email</TableCell>
            <TableCell align="right">Actions</TableCell>


          
          </TableRow>
        </TableHead>
        <TableBody>
          {accounts.map((row, index) => (
            <TableRow
              key={index}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell component="th" scope="row">
                {row.username}
              </TableCell>
              <TableCell align="right">{row.profile.name}</TableCell>
              <TableCell align="right">{row.profile.email}</TableCell>
              <TableCell align="right">
                <IconButton 
                onClick={() => {
                  setResetPasswordOpen(true);
                  setResetId(row.id);
                }}
                color="error" >
                <RotateLeftIcon />
                </IconButton></TableCell>
             
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>

<div
className='fab-container'
>
<Fab variant="extended" color='primary' 
onClick={() => setAddAccountOpen(true)}
>
  <AddIcon sx={{ mr: 1 }} />
  Add Account
</Fab>
</div>
    </div>
    </Container>
    </Page>
  )
}
