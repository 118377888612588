import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Fab from "@mui/material/Fab";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { IconButton, Typography } from "@mui/material";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import EditTeacherDialog from "./EditTeacherDialog";
import Iconify from '../../components/Iconify';
import AddTeacherialog from "./AddTeacherDialog";
import { getRequest, postRequest } from "../../utils/NetworkHandler";


export default function Teachers() {
  useEffect(() => {
    getData();
  }, []);


  const handleAddAccountClose = () => {
    setAddAccountOpen(false);
  };

  const handleEditMadrasaClose = () =>{
    setEditTeacherOpen(false);
  }

  const onRefresh = () => {
    getData();
  };
  const [accounts, setAccounts] = useState([]);
  const [madrasas, setMadrasas] = useState([]);

  const onSuccess = (response) => {
    const {data} = response;
   
    setAccounts(data.teachers);
    setMadrasas(data.madrasas);
  };

  const onError = (error) => {
    alert(error.toString());
  };

  const getData = () => {
    getRequest(
      "/landing/api/ummah/get-teachers",
      null,
      onSuccess,
      onError,
      true
    );
  };

  const [addAccountOpen, setAddAccountOpen] = useState(false);
  const [editTeacherOpen, setEditTeacherOpen] = useState(false);
  const [editteacher, setEditTeacher] = useState("-1");
  const [batches, setBatches] = useState([]);
  

  return (
    <div>
      <AddTeacherialog
        open={addAccountOpen}
        madrasas={madrasas}
        handleClose={handleAddAccountClose}
        onRefresh={onRefresh}
      />
         <EditTeacherDialog
      open={editTeacherOpen}
      handleClose={handleEditMadrasaClose}
      editObj={editteacher}
      batches={batches}
      onRefresh = {onRefresh}
/>

      <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
          Teachers
          </Typography>
          <Button variant="contained" onClick={() => setAddAccountOpen(true)} startIcon={<Iconify icon="eva:plus-fill" />}>
          Add Teacher
          </Button>
        </Stack>




      {/* <Typography variant="h4" gutterBottom>
        Teachers
      </Typography> */}
      {accounts.map((data, index) => (
        <MadrasaTable key={index} name={data.name} accounts={data.teachers} />
      ))}
      {/* <div className="fab-container">
        <Fab
          variant="extended"
          color="primary"
          onClick={() => setAddAccountOpen(true)}
        >
          <AddIcon sx={{ mr: 1 }} />
          Add Teacher
        </Fab>
      </div> */}
    </div>
  );
    function MadrasaTable(props) {
      const {name} = props;
      const {accounts} = props;
      const [open, setOpen] = React.useState(false);

      const handleClickOpen = () => {
        setOpen(true);
      };
    
      
      return (
        <div>
          <Typography
            sx={{ pt: 2, pb: 1 }}
            fontSize={18}
            color="text.secondary"
            component="div"
            variant="h6"
          >
            {name}
          </Typography>
          <TableContainer component={Paper} sx={{ mb: 10 }}>
            <Table aria-label="Accounts">
              <TableHead>
                <TableRow>
                  <TableCell>Username</TableCell>
                  <TableCell align="right">Name</TableCell>
                  <TableCell align="right">Email</TableCell>
                  <TableCell align="right">Edit</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {accounts.map((row, index) => (
                  <TableRow
                    key={index}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      {row.username}
                    </TableCell>
                    <TableCell align="right">
                      {row.profile ? `${row.profile.name}` : "Not Available"}
                    </TableCell>
                    <TableCell align="right">
                      {row.profile ? `${row.profile.email}` : "Not Available"}
                    </TableCell>
                    <TableCell align="right">
                      <IconButton
                        onClick={() => {
                  setEditTeacherOpen(true);
                  setEditTeacher(row);
                  // console.log(row)
                }}
                        color="primary"
                      >
                        <EditIcon />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
  );
}
}   
