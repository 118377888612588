import React, {useState} from 'react'
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import {Box} from "@mui/material";
import Slide from '@mui/material/Slide';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { postRequest } from '../../utils/NetworkHandler';

const Transition = React.forwardRef((props, ref) => <Slide direction="left" ref={ref} {...props} />);


export default function AddAnnouncement(props) {
    const [maxWidth, setMaxWidth] = React.useState('xs');
    const [fullWidth, setFullWidth] = React.useState(true);
    const [loading, setLoading] = useState(false);

    const [title, setTitle] = useState("");
    const [titleHelper, setTitleHelper] = useState("");

    const [description, setDescription] = useState("");
    const [descriptionHelper, setDescriptionHelper] = useState("");

    const {madrasas} = props;
    const [selected, setSelected] = useState("-1");
  
    const requiredText = "This field is required";

    const saveAccount = () => {
        setLoading(true);
        let formValidated = true;
        if(title === ""){
            formValidated = false;
            setTitleHelper(requiredText);
        }

          if(description === ""){
            formValidated = false;
            setDescriptionHelper(requiredText);
        }

        if(selected === "-1"){
          formValidated = false;
          alert("Please select a madrasa");
        }
       

        if(formValidated){
            const data = {'title': title, "description": description,  "madrasa_id": selected}
            postRequest("/landing/api/ummah/add-announcement", data, onSuccess, onError, true);
        }
        else{
            setLoading(false);
        }

    }

    const onSuccess = (response)=> {
        setLoading(false);

        const {data} = response;
        if(data.status){
            setTitle("");
            setDescription("");
          setSelected("-1");
            handleClose();
            props.onRefresh();
        }
        else{
            const {message} = data;
            setTitleHelper(message);
        }
    }

    const onError = (error) => {
        setLoading(false);
        alert(error.toString());
    }

    const handleClose = () => {
        setTitleHelper("");
        setDescriptionHelper("");
        props.handleClose();

    }

    const handleMadrasaChange = (e) => {
      setSelected(e.target.value);
    }
    

  return (
    <div>
      
      <Dialog open={props.open} onClose={handleClose}
      maxWidth={maxWidth}
      fullWidth={fullWidth}
        TransitionComponent={Transition}
      >
        <DialogTitle>Add an Announcement</DialogTitle>
        <DialogContent>
          <DialogContentText>
            This action cannot be undone, please enter details with caution.
          </DialogContentText>
         <Box sx={{display:"flex", gap:'10px'}} >
         <TextField
            error={titleHelper !== "" }
            helperText={titleHelper}
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            autoFocus
            margin="dense"
            id="title"
            label="Title"
            type="text"
            variant="standard"
          />

        
         </Box>

         <TextField
            error={descriptionHelper !== "" }
            helperText={descriptionHelper}
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            autoFocus
            sx={{mt:2}}
            margin="dense"
            id="description"
            label="Description"
            type="text"
            multiline
            rows={3}
            variant="standard"
          />


         <FormControl
      sx={{mt:2}}
      fullWidth>
        <InputLabel id="assign-to-label">Madrasa</InputLabel>
        <Select
          labelId="assign-to-label"
          id="assign-to-select"
          value={selected}
          label="Assign To"
          onChange={handleMadrasaChange}
        >
          <MenuItem value={"-1"}>Select a madrasa</MenuItem>
          {madrasas.map(( item, index) => (
          <MenuItem
          key={index}
          value={item.id}> {item.name}</MenuItem>
          ) )}
        </Select>
      </FormControl>


        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button disabled={loading}  onClick={() => saveAccount() }>Save</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}


