/* eslint-disable */
import React, {useState, useEffect} from 'react'
import Box from '@mui/material/Box';
import Fab from '@mui/material/Fab';
import AddIcon from '@mui/icons-material/Add';
import {Typography, IconButton} from "@mui/material";
import {getRequest} from "../../../utils/NetworkHandler";

import EditIcon from '@mui/icons-material/Edit';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

import AddMasjidDialog from "./AddMasjidDialog";
import EditMadrasaDialog from "../Madrasa/EditMadrasaDialog";
import EditMasjidDialog from './EditMadrasaDialog';

export default function AddMasjid() {

  useEffect( () => {
    getData();
  },  [])

  const handleAddMasjidClose = () =>{
    setAddMasjidOpen(false);
  }

  const handleEditMasjidClose = () =>{
    setEditMasjidOpen(false);
  }

  const onRefresh = () => {
    getData();
    setAddMasjidOpen(false)
    setEditMasjidOpen(false)
    // console.log("onrefressh is called")
    window.location.reload(false)
  }
  const [madrasas, setMadrasas] = useState([]);

  const onSuccess = (response) => {
    console.log(response.data)
    var data = response.data;
    setMadrasas(data);
  }

  const onError = (error) => {
    alert(error.toString())
  }

  const getData = () => {
      getRequest("/masjid/api/getmasjid", null, onSuccess, onError, true);
  }

   
  const [addMasjidOpen, setAddMasjidOpen] = useState(false);
  const [editMasjidOpen, setEditMasjidOpen] = useState(false);
  const [editMasjid, setEditmasjid] = useState("-1");

  return (
    <div>

<AddMasjidDialog
      open={addMasjidOpen}
      handleClose={handleAddMasjidClose}
      onRefresh = {onRefresh}
      />
        <EditMasjidDialog
      open={editMasjidOpen}
      handleClose={handleEditMasjidClose}
      editObj={editMasjid}
      onRefresh = {onRefresh}
/>
      
      <Typography component="div" variant="h5" sx={{fontSize:18, pb:2}} >Masjid</Typography>

      <TableContainer component={Paper}
      sx={{mb:10}}
      >
      <Table aria-label="Accounts">
        <TableHead>
          <TableRow>
            <TableCell>Masjid Name</TableCell>
            <TableCell>Thumbnail Image</TableCell>
            <TableCell align="center">Phone Number</TableCell>
            <TableCell align="center">email_address</TableCell>
            <TableCell align="center">Address</TableCell>
            <TableCell align="center">Location</TableCell>
            <TableCell align="center">Actions</TableCell>


          
          </TableRow>
        </TableHead>
        <TableBody>
          {madrasas.map((row, index) => (
            <TableRow
              key={index}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell component="th" scope="row">
                {row.name}
              </TableCell>
              <TableCell component="th" scope="row">
              <img 
      src={row.thumbnail_url}
      width="150"
      alt="new"
      />
              </TableCell>
              <TableCell align='center'>{row.phone_number}</TableCell>
              <TableCell align="center">{row.email_address}</TableCell>
              <TableCell align="center">{row.address_street},{row.address_city},<br/>{row.address_state},{row.address_country} - {row.address_postal_code} </TableCell>
             
              <TableCell align="center">Latitude:- {row.latitude}<br/>Longitude:- {row.longitude} </TableCell>
              <TableCell align="right">
                <IconButton 
                onClick={() => {
                  setEditMasjidOpen(true);
                  setEditmasjid(row);
                }}
                color="primary" >
                <EditIcon />
                </IconButton>
                </TableCell>
             
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>


      <div
className='fab-container'
>
<Fab variant="extended" color='primary' 
onClick={() => setAddMasjidOpen(true)}
>
  <AddIcon sx={{ mr: 1 }} />
  Add Masjid
</Fab>
</div>

    </div>
  )
}
