/* eslint-disable array-callback-return */
/* eslint-disable prefer-const */
import React, { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { IconButton, Typography } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';

import Collapse from '@mui/material/Collapse';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import Button from '@mui/material/Button';
import { getRequest } from '../../utils/NetworkHandler';
import ShowFeedBackDialog from './ShowFeedbackDialog';
import ShowTeachersFeedBackDialog from './ShowTeacherFeedbackDialog';

export default function TeacherMapping() {
  useEffect(() => {
    getData();
  }, []);

  const [accounts, setAccounts] = useState([]);
  const [madrasas, setMadrasas] = useState([]);
  const [student, setStudent] = useState([]);

  const onSuccess = (response) => {
    console.log(response.data);
    const { data } = response;
    setStudent(data.students);
    setAccounts(data.teachers);
    setMadrasas(data.madrasas);
  };

  const onError = (error) => {
    alert(error.toString());
  };

  const getData = () => {
    getRequest('/landing/api/ummah/get-mapping', null, onSuccess, onError, true);
  };

  const [editStudentOpen, setEditStudentOpen] = useState(false);
  const [editStudent, setEditStudent] = useState('');

  function studentList(name, students) {
    let student;
    let newlist;

    [students].map((data, index) => {
      newlist = data[index].students;
    });

    if (!newlist || newlist.length === 0) {
      return (
        <Typography>
          <h5 align={'right'}>No students were assigned to this teacher.</h5>
        </Typography>
      );
    }

    student = newlist.filter((data) => data.assigned_teacher && data.assigned_teacher.username === name);

    if (student.length <= 0) {
      return (
        <Typography>
          <h5 align={'right'}>No students were assigned to this teacher.</h5>
        </Typography>
      );
    }

    return (
      <>
        {student.map((data, index) => (
          <TableRow key={index}>
            <TableCell component="th" scope="row">
              {data.name}
            </TableCell>
            <TableCell> {data.emergency_contact}</TableCell>
            <TableCell> {data.parent_name}</TableCell>
            <TableCell align="left">
              {' '}
              <Button variant="text" onClick={() => setEditStudent(data)}>
                View
              </Button>
            </TableCell>
            <TableCell align="left">
              {' '}
              <Button variant="text" onClick={() => setEditStudent(data)}>
                View
              </Button>
            </TableCell>
            <TableCell align="left">
              {' '}
              <IconButton onClick={() => setEditStudentOpen(true)}>
                <EditIcon />
              </IconButton>
            </TableCell>
          </TableRow>
        ))}
      </>
    );
  }

  function Row(props) {
    const { row } = props;
    const [open, setOpen] = React.useState(false);

    return (
      <>
        <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
          <TableCell>
            <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
              {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
          </TableCell>

          <TableCell component="th" scope="row">
            {row.username}
          </TableCell>
          <TableCell align="right"> {row.profile ? `${row.profile.name}` : 'Not Available'}</TableCell>
          <TableCell align="right"> {row.profile ? `${row.profile.email}` : 'Not Available'}</TableCell>
        </TableRow>
        <TableRow>
          <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
            <Collapse in={open} timeout="auto" unmountOnExit>
              <Box sx={{ margin: 1 }}>
                <Typography variant="h6" gutterBottom component="div">
                  Students
                </Typography>
                <Table size="small" aria-label="purchases">
                  <TableHead>
                    <TableRow>
                      <TableCell>Students Name</TableCell>
                      <TableCell>Contact Number</TableCell>
                      <TableCell>Parents Name</TableCell>
                      <TableCell>Teachers Feedbacks</TableCell>
                      <TableCell>Parents Feedbacks</TableCell>
                      <TableCell align="right">Edit</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {studentList(row.username, student)}
                  </TableBody>
                </Table>
              </Box>
            </Collapse>
          </TableCell>
        </TableRow>
      </>
    );
  }

  return (
    <div>
      <Typography component="div" variant="h5" sx={{ fontSize: 18, pb: 2 }}>
        Teachers
      </Typography>
      {accounts.length > 0 ? (
        accounts.map((data, index) => (
          <MadrasaTable key={data.name} name={data.name} accounts={data.teachers} />
        ))
      ) : (
        <Typography>No data available</Typography>
      )}

      <ShowFeedBackDialog open={editStudentOpen} studentdata={editStudent} onClose={() => setEditStudentOpen(false)} />
      <ShowTeachersFeedBackDialog open={editStudentOpen} studentdata={editStudent} onClose={() => setEditStudentOpen(false)} />
    </div>
  );

  function MadrasaTable(props) {
    const { name } = props;
    const { accounts } = props;

    return (
      <div>
        <Typography sx={{ pt: 2, pb: 1 }} fontSize={18} color="text.secondary" component="div" variant="h6">
          {name}
        </Typography>
        <TableContainer component={Paper}>
          <Table aria-label="collapsible table">
            <TableHead>
              <TableRow>
                <TableCell />
                <TableCell>Username</TableCell>
                <TableCell align="right">Email</TableCell>
                <TableCell align="right">Number</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {accounts.map((row) => (
                <Row key={row.username} row={row} />
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    );
  }
}
