import { Suspense, lazy } from 'react';
import { Navigate, useRoutes, useLocation } from 'react-router-dom';
// layouts
import { element } from 'prop-types';
// import { Login } from '../LoginPage';
import DashboardLayout from '../layouts/dashboard';
import LogoOnlyLayout from '../layouts/LogoOnlyLayout';
// components
import LoadingScreen from '../components/LoadingScreen';
import Login from '../LoginPage';
import SuperadminLayout from '../layouts/superadmin';
import { SuperAdminDashboard } from '../layouts/superadmin/SuperAdminDashboard';
import Volunteer from '../layouts/superadmin/Volunteer/volunteer';
import VolunteerCategories from '../layouts/superadmin/Volunteer/VolunteerCategories';
import Job from '../layouts/superadmin/Job/job';
import JobCategories from '../layouts/superadmin/Job/jobcategories';
import Dawaah from '../layouts/superadmin/Dawaah/dawaah';
import DawaahCategories from '../layouts/superadmin/Dawaah/DawaahCategories';
import Actiivity from '../layouts/superadmin/Activity/activity';
import ActivityCategories from '../layouts/superadmin/Activity/ActvityCategories';
import Event from '../layouts/superadmin/Event/event';
import EventCategories from '../layouts/superadmin/Event/EventCategories';
import Networking from '../layouts/superadmin/Networking/Networking';
import NetworkingCategories from '../layouts/superadmin/Networking/NetworkingCategories';
import UmrahAndHajj from '../layouts/superadmin/Umrahandhajj/umrahandhajj';
import SuperAdminMadrasas from '../layouts/superadmin/Madrasa/Madrasas';
import Home from '../layouts/superadmin/Account/Home';
import AdminLayout from '../layouts/admin';
import Madrasas from '../layouts/admin/Madrasas';
import Teachers from '../layouts/admin/Teachers';
import Announcements from '../layouts/admin/Announcements';
import Payments from '../layouts/admin/Payments';
import TeacherMapping from '../layouts/admin/TeacherMapping';
import Enrollments from '../layouts/admin/Enrollments';
import { AdminDashboard } from '../layouts/admin/AdminDashboard';
import FAQS from '../layouts/superadmin/Faqs/Faqs';
import AddMasjid from '../layouts/superadmin/masjid/AddMasjid';
import AdminMasjidLayout from '../layouts/Masjidadmin';
import Masjid from '../layouts/Masjidadmin/Madrasas';
import MasjiAnnouncements from '../layouts/Masjidadmin/Announcements';
import NamazTimings from '../layouts/Masjidadmin/NamazTimings';
import IslamicLearning from '../layouts/superadmin/IslamicLearning/IslamicLearning';

// ----------------------------------------------------------------------

const Loadable = (Component) => (props) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { pathname } = useLocation();

  return (
    <Suspense fallback={<LoadingScreen isDashboard={pathname.includes('/dashboard')} />}>
      <Component {...props} />
    </Suspense>
  );
};

export default function Router() {
  return useRoutes([
    {
      path:'/',
      element:<Login/>,
    },
    {
      path: '/admin',
      element: <AdminLayout />,
      children: [
        { element: <Navigate to="/admin/dashboard" replace />, index: true },
        { path: '/admin', element: <Navigate to="/admin/dashboard" replace />, index: true },
        { path: '/admin/dashboard', element: <AdminDashboard /> },
        { path: '/admin/madrasas', element: <Madrasas /> },
        { path: '/admin/teachers', element: <Teachers /> },
        { path: '/admin/enrollments', element: <Enrollments /> },
        { path: '/admin/announcements', element: <Announcements /> },
        { path: '/admin/payments', element: <Payments /> },
        { path: '/admin/teachermapping', element: <TeacherMapping /> },
      ],
    },

    {
      path: '/masjidadmin',
      element: <AdminMasjidLayout />,
      children: [
        { element: <Navigate to="/masjidadmin/dashboard" replace />, index: true },
        { path: '/masjidadmin', element: <Navigate to="/masjid/dashboard" replace />, index: true },
        { path: '/masjidadmin/dashboard', element: <AdminDashboard /> },
        { path: '/masjidadmin/masjid', element: <Masjid /> },
        { path: '/masjidadmin/masjidannouncements', element: <MasjiAnnouncements /> },
        { path: '/masjidadmin/masjidnamaztimings', element: <NamazTimings /> },
      ],
    },
    {
      path: '/superadmin',
      element: <SuperadminLayout />,
      children: [
        { element: <Navigate to="/superadmin/dashboard" replace />, index: true },
        { path: '/superadmin', element: <Navigate to="/superadmin/dashboard" replace />, index: true },
        { path: '/superadmin/dashboard', element: <SuperAdminDashboard /> },
        { path: '/superadmin/umrahandhajj', element: <UmrahAndHajj /> },
        { path: '/superadmin/faqs', element: <FAQS /> },
        {path:'/superadmin/IslamicLearning',element:<IslamicLearning/>},
        {
          path: '/superadmin/masjid',
          children: [
            { element: <Navigate to="/superadmin/masjid/addmasjid" replace />, index: true },
            { path: '/superadmin/masjid/addmasjid', element: <AddMasjid /> },
          ],
        },
        {
          path: '/superadmin/madrasas',
          children: [
            { element: <Navigate to="/superadmin/madrasas/madrasas" replace />, index: true },
            { path: '/superadmin/madrasas/madrasas', element: <SuperAdminMadrasas /> },
            { path: '/superadmin/madrasas/accounts', element: <Home /> },
            { path: '/superadmin/madrasas/addmasjid', element: <AddMasjid /> },
          ],
        },
        {
          path: '/superadmin/volunteer',
          children: [
            { element: <Navigate to="/superadmin/volunteer/volunteer" replace />, index: true },
            { path: '/superadmin/volunteer/volunteer', element: <Volunteer /> },
            { path: '/superadmin/volunteer/volunteercategories', element: <VolunteerCategories /> },
          ],
        },
        {
          path: '/superadmin/job',
          children: [
            { element: <Navigate to="/superadmin/job/job" replace />, index: true },
            { path: '/superadmin/job/job', element: <Job /> },
            { path: '/superadmin/job/jobcategories', element: <JobCategories /> },
          ],
        },
        {
          path: '/superadmin/dawaah',
          children: [
            { element: <Navigate to="/superadmin/dawaah/dawaah" replace />, index: true },
            { path: '/superadmin/dawaah/dawaah', element: <Dawaah /> },
            { path: '/superadmin/dawaah/dawaahcategories', element: <DawaahCategories /> },
          ],
        },
        {
          path: '/superadmin/activity',
          children: [
            { element: <Navigate to="/superadmin/activity/activity" replace />, index: true },
            { path: '/superadmin/activity/activity', element: <Actiivity /> },
            { path: '/superadmin/activity/activitycategories', element: <ActivityCategories /> },
          ],
        },
        {
          path: '/superadmin/event',
          children: [
            { element: <Navigate to="/superadmin/event/event" replace />, index: true },
            { path: '/superadmin/event/event', element: <Event /> },
            { path: '/superadmin/event/eventcategories', element: <EventCategories /> },
          ],
        },
        {
          path: '/superadmin/networking',
          children: [
            { element: <Navigate to="/superadmin/networking/networking" replace />, index: true },
            { path: '/superadmin/networking/networking', element: <Networking /> },
            { path: '/superadmin/networking/networkingcategories', element: <NetworkingCategories /> },
          ],
        },
      ],
    },
    {
      path: '*',
      element: <LogoOnlyLayout />,
      children: [
        { path: '404', element: <NotFound /> },
        { path: '*', element: <Navigate to="/404" replace /> },
      ],
    },
    { path: '*', element: <Navigate to="/404" replace /> },
  ]);
}

// Dashboard
const PageOne = Loadable(lazy(() => import('../pages/PageOne')));
const PageTwo = Loadable(lazy(() => import('../pages/PageTwo')));
const PageThree = Loadable(lazy(() => import('../pages/PageThree')));
const PageFour = Loadable(lazy(() => import('../pages/PageFour')));
const PageFive = Loadable(lazy(() => import('../pages/PageFive')));
const PageSix = Loadable(lazy(() => import('../pages/PageSix')));
const NotFound = Loadable(lazy(() => import('../pages/Page404')));
