import React, { useState, useEffect } from "react";
import { Helmet } from 'react-helmet-async';
import { Grid, Container, Typography } from '@mui/material';

  import { useTheme } from '@mui/material/styles';
  // import { faker } from '@faker-js/faker';
import { getRequest } from "../../utils/NetworkHandler";
// import Iconify from '../../components/iconify';
import AppWidgetSummary from '../../components/AppWidgetSummary';


export const AdminDashboard = () => {
    const theme = useTheme();
    useEffect(() => {
      getData();
    }, []);

    const onRefresh = () => {

      getData();
      
    };
    const [accounts, setAccounts] = useState([]);
    const [madrasas, setMadrasas] = useState([]);
    const [student,setStudent]=useState([]);
    const [numberOfteachers, setTeacherCount]=useState("");
    const onSuccess = (response) => {
      const {data} = response;
      setStudent(data.students)
     setTeacherCount(data.teachers)
      // //console.log(numberOfteachers)
      setMadrasas(data.madrasas);
    };
     
    const onError = (error) => {
      alert(error.toString());
    };
  
    const getData = () => {
      getRequest(
        "/landing/api/ummah/get-dashboard",
        null,
        onSuccess,
        onError,
        true
      );


    };
  return (
    <>
    {/* // <><Helmet>
    //       <title> Dashboard | Ummah </title>
    //   </Helmet> */}
      <Container maxWidth="xl">
              <Typography variant="h4" sx={{ mb: 5 }}>
                  Hi, Welcome back
              </Typography>

              <Grid container spacing={3}>
                  <Grid item xs={12} sm={6} md={3}>
                      <AppWidgetSummary title="Total Madrasa" total={madrasas.length} icon={'fluent:building-bank-24-filled'} />
                  </Grid>

                  <Grid item xs={12} sm={6} md={3}>
                      <AppWidgetSummary title="Total Teachers" total={numberOfteachers} color="info" icon={'fa-solid:chalkboard-teacher'} />
                  </Grid>

                  <Grid item xs={12} sm={6} md={3}>
                      <AppWidgetSummary title="Total Students" total={student} color="warning" icon={'icons8:student'} />
                  </Grid>

                  

              </Grid>
          </Container>
    </>

  )
}
