/* eslint-disable camelcase */
/* eslint-disable jsx-a11y/media-has-caption */
import { Button, Card, Col, Row, Space, Switch, Table, Tag } from 'antd';
import React, { useEffect, useState } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
import { useNavigate } from 'react-router';
import { IconButton, TableBody, TableContainer, TableHead, TableRow } from '@mui/material';
import { Paper, TableCell } from '@material-ui/core';
import { Link } from 'react-router-dom';
import { apptokenKey, getRequest, postRequest } from '../../../utils/NetworkHandler';


const IslamicLearning = () => {
  const [fullWidth, setFullWidth] = React.useState(true);
  const [maxWidth, setMaxWidth] = React.useState('sm');

  const navigator = useNavigate();
  useEffect(() => {
    if (localStorage.getItem(apptokenKey)) {
      getData();
    } else {
      navigator('/login');
    }
  }, []);

  const [categories, setcategories] = useState([]);

  const onSuccess = (response) => {
    console.log(response.data);
    setcategories(response.data);
  };

  const onError = (error) => {
    alert(error.toString());
  };

  const getData = () => {
    getRequest('/IslamicLearning/api/getdata', null, onSuccess, onError);
  };

  const HandleAddCategory = () => {
    console.log('Add category');
    handleClickOpen();
  };

  const [open, setOpen] = React.useState(false);
  const [category, setcategory] = useState();
  const [categoryid, setcategoryid] = useState();

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const onAddSuccess = (response) => {
    console.log(response.data);
    getData();
  };

  const onAddError = (error) => {
    alert(error.toString());
  };

  const handleAdd = () => {
    setOpen(false);
    if (category === undefined) {
      alert('Invaild Category Name');
    } else {
      const data = { categoryname: category };
      postRequest('/IslamicLearning/api/addcategory', data, onAddSuccess, onAddError, false);
    }
  };
  const HandleAddSubCategory = (id) => {
    setcategoryid(id);
    console.log('Add category');
    handleClickSubOpen();
  };

  const [openSub, setSubOpen] = React.useState(false);
  const [title, settitle] = useState();
  const [videourl, setvideourl] = useState();
  const [thumbnailUrl, setThnumnailUrl] = useState();

  const handleClickSubOpen = () => {
    setSubOpen(true);
  };

  const handleSubClose = () => {
    setSubOpen(false);
  };

  const onAddSubSuccess = (response) => {
    console.log(response.data);
    getData();
  };

  const onAddSubError = (error) => {
    alert(error.toString());
  };

  const handleAddSub = () => {
    setSubOpen(false);
    if (videourl=== undefined) {
      alert('Invaild Video URL');
    } else {
      const data = {title,thumbnailUrl,videourl, id: categoryid };
      postRequest('/IslamicLearning/api/AddVideo', data, onAddSubSuccess, onAddSubError, false);
    }
  };

  const onDeleteSuccess = (response) => {
    console.log(response.data);
    getData();
  };

  const onDeleteError = (error) => {
    alert(error.toString());
  };

  const handleDelete=(id)=>{
    postRequest(`/IslamicLearning/api/delete/${id}`,null, onDeleteSuccess, onDeleteError, false);
  }


  categories.map((videos,index)=>{
    console.log(videos.videos)
    videos.videos.map((vid,ind)=>{
      console.log(vid)
      return vid
    })
    return videos
  })
  const columns = [
    {
      title: 'Title',
      dataIndex: 'title',
      key: 'title',
      render: (text) => <a>{text}</a>,
      width: 300,
    },
    {
      title: 'Thumbnail Url',
      dataIndex: 'thumbnail_url',
      key: 'thumbnail_url',
      render: (thumbnail_url) => (
        <img src={thumbnail_url} alt="thumbnail" style={{ maxWidth: '100px' }} />
      ),
    },
    {
      title: 'Video Url',
      dataIndex: 'video_url',
      key: 'video_url',
      render: (video_url) => (
        <a>{video_url}</a>
      ),
    },
    // {
    //   title: 'Edit',
    //   key: 'edit',
    //   dataIndex: 'edit',
    //   render: (id) => (
    //     <Button onClick={() => handleDelete(id)} type="primary">Edit</Button> 
    //   ),
    // },
    {
      title: 'Delete',
      key: 'delete',
      dataIndex: 'delete',
      render: (id) => (
        <Space size="middle">
        <Button onClick={() => handleDelete(id)} type="primary" danger>Delete</Button> 
        </Space>
      ),
    },
  ];
  
  const getTableData = (category) => {
    console.log(category.videos);
    const sampleData = category.videos.map((vid, index) => ({
      key: `${category.id}-${index}`,
      title: vid.title,
      thumbnail_url: vid.thumbnail_url,
      video_url: vid.video_url,
      edit: vid.id,
      delete: vid.id,
    })).flat();
    return <Table columns={columns} dataSource={sampleData} />;
  };
  
  

  return (
    <>
      <Dialog open={openSub} fullWidth={fullWidth} maxWidth={maxWidth} onClose={handleSubClose}>
        <DialogTitle>Add a Video</DialogTitle>
        <DialogContent>
          <TextField
            margin="dense"
            id="name"
            label="Title"
            onChange={(e) => settitle(e.target.value)}
            type="text"
            fullWidth
            variant="standard"
          />
          <TextField
            margin="dense"
            id="name"
            label="Video Url"
            onChange={(e) => setvideourl(e.target.value)}
            type="text"
            fullWidth
            variant="standard"
          />
          <TextField
            margin="dense"
            id="name"
            label="Thumbnail Url"
            onChange={(e) => setThnumnailUrl(e.target.value)}
            type="text"
            fullWidth
            variant="standard"
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleSubClose}>Close</Button>
          <Button onClick={handleAddSub}>Add</Button>
        </DialogActions>
      </Dialog>
      <Dialog open={openSub} fullWidth={fullWidth} maxWidth={maxWidth} onClose={handleSubClose}>
        <DialogTitle>Edit Video</DialogTitle>
        <DialogContent>
          <TextField
            margin="dense"
            id="name"
            label="Title"
            onChange={(e) => settitle(e.target.value)}
            type="text"
            fullWidth
            variant="standard"
          />
          <TextField
            margin="dense"
            id="name"
            label="Video Url"
            onChange={(e) => setvideourl(e.target.value)}
            type="text"
            fullWidth
            variant="standard"
          />
          <TextField
            margin="dense"
            id="name"
            label="Thumbnail Url"
            onChange={(e) => setThnumnailUrl(e.target.value)}
            type="text"
            fullWidth
            variant="standard"
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleSubClose}>Close</Button>
          <Button onClick={handleAddSub}>Add</Button>
        </DialogActions>
      </Dialog>
      <Dialog open={open} fullWidth={fullWidth} maxWidth={maxWidth} onClose={handleClose}>
        <DialogTitle>Add a category</DialogTitle>
        <DialogContent>
          <TextField
            margin="dense"
            id="name"
            label="Category"
            onChange={(e) => setcategory(e.target.value)}
            type="text"
            fullWidth
            variant="standard"
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Close</Button>
          <Button onClick={handleAdd}>Add</Button>
        </DialogActions>
      </Dialog>
      <Card
        title="Categories"
        extra={
          <Button onClick={() => HandleAddCategory()} type="link">
            Add Category
          </Button>
        }
      >
        {categories.map((Category, index) => (
          <>
            <Card
            key={index}
              title={Category.name}
              bordered
              extra={
                <Button onClick={() => HandleAddSubCategory(Category.id)} type="link">
                  Add Video
                </Button>
              }
            >
            {getTableData(Category)}
            </Card>
            <br />
          </>
        ))}
      </Card>
    </>
  );
};
export default IslamicLearning;
