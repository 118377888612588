/* eslint-disable */
import React, { useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { Box } from '@mui/material';
import Slide from '@mui/material/Slide';
import { postRequest } from '../../../utils/NetworkHandler';
import Batch from '../../../components/Batch';
// import { BatchList } from './Batch';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="left" ref={ref} {...props} />;
});

export default function AddMasjidDialog(props) {
  const [maxWidth, setMaxWidth] = React.useState('xs');
  const [desc, setDescName] = useState('');
  const [fullWidth, setFullWidth] = React.useState(true);
  const [loading, setLoading] = useState(false);

  const [MasjidName, setMasjidName] = useState('');
  const [PhoneNumber, setPhoneNumber] = useState('');
  const [EmailAddress, setEmailAddress] = useState('');
  const [ThumbnailUrl, setThumbnailUrl] = useState('');
  const [Street, setStreet] = useState('');
  const [City, setCity] = useState('');
  const [State, setState] = useState('');
  const [Country, setCountry] = useState('');
  const [Postalcode, setPostalcode] = useState('');
  const [Latitude, setLatitude] = useState('');
  const [Longitude, setLongitude] = useState('');
  const [Password, setPassword] = useState('');

  const [MasjidNameHelper, setMasjidNameHelper] = useState('');
  const [PhoneNumberHelper, setPhoneNumberHelper] = useState('');
  const [EmailAddressHelper, setEmailAddressHelper] = useState('');
  const [ThumbnailUrlHelper, setThumbnailUrlHelper] = useState('');
  const [StreetHelper, setStreetHelper] = useState('');
  const [CityHelper, setCityHelper] = useState('');
  const [StateHelper, setStateHelper] = useState('');
  const [CountryHelper, setCountryHelper] = useState('');
  const [PostalcodeHelper, setPostalcodeHelper] = useState('');
  const [LatitudeHelper, setLatitudeHelper] = useState('');
  const [LongitudeHelper, setLongitudeHelper] = useState('');
  const [PasswordHelper, setPasswordHelper] = useState('');

  let requiredText = 'This field is required';

  const saveAccount = () => {
    setLoading(true);
    let formValidated = true;

    if (MasjidName === '') {
      formValidated = false;
      setMasjidNameHelper(requiredText);
    }

    if (PhoneNumber === '') {
      formValidated = false;
      setPhoneNumberHelper(requiredText);
    }

    if (EmailAddress === '') {
      formValidated = false;
      setEmailAddressHelper(requiredText);
    }
    if (ThumbnailUrl === '') {
      formValidated = false;
      setThumbnailUrlHelper(requiredText);
    }
    if (Street === '') {
      formValidated = false;
      setStreetHelper(requiredText);
    }
    if (City === '') {
      formValidated = false;
      setCityHelper(requiredText);
    }
    if (State === '') {
      formValidated = false;
      setStateHelper(requiredText);
    }
    if (Country === '') {
      formValidated = false;
      setCountryHelper(requiredText);
    }

    if (Postalcode === '') {
      formValidated = false;
      setPostalcodeHelper(requiredText);
    }
    if (Latitude === '') {
      formValidated = false;
      setLatitudeHelper(requiredText);
    }
    if (Longitude === '') {
      formValidated = false;
      setLongitudeHelper(requiredText);
    }
    if (Password === '') {
      formValidated = false;
      setPasswordHelper(requiredText);
    }

    if (formValidated) {
      var data = {
        name: MasjidName,
        address_street: Street,
        address_city: City,
        address_state: State,
        address_country: Country,
        address_postal_code: Postalcode,
        latitude: Latitude,
        longitude: Longitude,
        phone_number: PhoneNumber,
        email_address: EmailAddress,
        thumbnail_url: ThumbnailUrl,
        Password: Password,
      };
      postRequest('/masjid/api/addmasjid', data, onSuccess, onError, true);
    } else {
      setLoading(false);
    }
  };

  const onSuccess = (response) => {
    setLoading(false);

    var data = response.data;
    if (data.status) {
      setMasjidName('');
      setPhoneNumber('');
      setEmailAddress('');
      setThumbnailUrl('');
      setStreet('');
      setCity('');
      setState('');
      setCountry('');
      setPostalcode('');
      setLatitude('');
      setLongitude('');
      setPassword('');
      props.onRefresh();
    } else {
    }
  };

  const onError = (error) => {
    setLoading(false);
    alert(error.toString());
  };

  const handleClose = () => {
    setMasjidNameHelper('');
    setPhoneNumberHelper('');
    setEmailAddressHelper('');
    setThumbnailUrlHelper('');
    setStreetHelper('');
    setCityHelper('');
    setStateHelper('');
    setCountryHelper('');
    setPostalcodeHelper('');
    setLatitudeHelper('');
    setLongitudeHelper('');
    setPasswordHelper('');
    props.handleClose();
  };

  return (
    <div>
      <Dialog
        open={props.open}
        onClose={handleClose}
        maxWidth={maxWidth}
        fullWidth={fullWidth}
        TransitionComponent={Transition}
      >
        <DialogTitle>Add a Masjid</DialogTitle>
        <DialogContent>
          <DialogContentText>Please fill in this form to proceed.</DialogContentText>
          <Box sx={{ display: 'flex', gap: '10px' }}>

          <TextField
            autoFocus
            margin="dense"
            id="masjid-name"
            label="Masjid Name"
            type="text"
            error={MasjidName !== '' ? true : false}
            helperText={MasjidNameHelper}
            value={MasjidName}
            onChange={(e) => setMasjidName(e.target.value)}
            variant="standard"
          />

<TextField
            autoFocus
            margin="dense"
            id="phone-number"
            label="Phone Number"
            type="text"
            error={PhoneNumber !== '' ? true : false}
            helperText={PhoneNumberHelper}
            value={PhoneNumber}
            onChange={(e) => setPhoneNumber(e.target.value)}
            variant="standard"
          />

          </Box>
          <Box sx={{ display: 'flex', gap: '10px' }}>
          <TextField
            autoFocus
            margin="dense"
            id="email-address"
            label="Email Address"
            type="email"
            error={EmailAddress !== '' ? true : false}
            helperText={EmailAddressHelper}
            value={EmailAddress}
            onChange={(e) => setEmailAddress(e.target.value)}
            variant="standard"
          />
 <TextField
            autoFocus
            margin="dense"
            id="password"
            label="Password"
            type="password"
            error={Password !== '' ? true : false}
            helperText={PasswordHelper}
            value={Password}
            onChange={(e) => setPassword(e.target.value)}
            variant="standard"
          />
            
</Box>
          <Box sx={{ display: 'flex', gap: '10px' }}>
          <TextField
            autoFocus
            margin="dense"
            id="thumbnail-url"
            label="Thumbnail Url"
            type="url"
            error={ThumbnailUrl !== '' ? true : false}
            helperText={ThumbnailUrlHelper}
            value={ThumbnailUrl}
            onChange={(e) => setThumbnailUrl(e.target.value)}
            variant="standard"
          />

          <TextField
            autoFocus
            margin="dense"
            id="street"
            label="Street"
            type="text"
            error={Street !== '' ? true : false}
            helperText={StreetHelper}
            value={Street}
            onChange={(e) => setStreet(e.target.value)}
            variant="standard"
          />
            
</Box>
          <Box sx={{ display: 'flex', gap: '10px' }}>
          <TextField
            autoFocus
            margin="dense"
            id="city"
            label="City"
            type="text"
            error={City !== '' ? true : false}
            helperText={CityHelper}
            value={City}
            onChange={(e) => setCity(e.target.value)}
            variant="standard"
          />

          <TextField
            autoFocus
            margin="dense"
            id="state"
            label="State"
            type="text"
            error={State !== '' ? true : false}
            helperText={StateHelper}
            value={State}
            onChange={(e) => setState(e.target.value)}
            variant="standard"
          />
            
</Box>
  <Box sx={{ display: 'flex', gap: '10px' }}>

  <TextField
            autoFocus
            margin="dense"
            id="country"
            label="Country"
            type="text"
            error={Country !== '' ? true : false}
            helperText={CountryHelper}
            value={Country}
            onChange={(e) => setCountry(e.target.value)}
            variant="standard"
          />

          <TextField
            autoFocus
            margin="dense"
            id="postalcode"
            label="Postal Code"
            type="text"
            error={Postalcode !== '' ? true : false}
            helperText={PostalcodeHelper}
            value={Postalcode}
            onChange={(e) => setPostalcode(e.target.value)}
            variant="standard"
          />
</Box>
  <Box sx={{ display: 'flex', gap: '10px' }}>
  <TextField
            autoFocus
            margin="dense"
            id="latitude"
            label="Latitude"
            type="number"
            error={Latitude !== '' ? true : false}
            helperText={LatitudeHelper}
            value={Latitude}
            onChange={(e) => setLatitude(e.target.value)}
            variant="standard"
          />
          <TextField
            autoFocus
            margin="dense"
            id="longitude"
            label="Longitude"
            type="number"
            error={Longitude !== '' ? true : false}
            helperText={LongitudeHelper}
            value={Longitude}
            onChange={(e) => setLongitude(e.target.value)}
            variant="standard"
          />
            
</Box>
         
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button disabled={loading} onClick={() => saveAccount()}>
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
