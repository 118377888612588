import React, {useState, useEffect} from 'react'
import Fab from '@mui/material/Fab';
import AddIcon from '@mui/icons-material/Add';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import {Typography} from "@mui/material"; 
import {getRequest} from "../../utils/NetworkHandler";
import AddAnnouncement from './AddAnnouncement';

export default function Announcements() {

  useEffect( () => {
    getData();
  },  [])

  const handleAddAnnouncementClose = () =>{
    setAddAnnouncementOpen(false);
  }



  const onRefresh = () => {
    getData();
  }
  const [announcements, setAnnouncements] = useState([]);
  const [madrasas, setMadrasas] = useState([]);

  const onSuccess = (response) => {
    const {data} = response;
    setAnnouncements(data.announcements);
    setMadrasas(data.madrasas);
  }

  const onError = (error) => {
    alert(error.toString())
  }

  const getData = () => {
      getRequest("/landing/api/ummah/get-announcements", null, onSuccess, onError, true);
  }

   
  const [addAnnouncementOpen, setAddAnnouncementOpen] = useState(false);

  return (
    <div>
      <AddAnnouncement
      open={addAnnouncementOpen}
      madrasas = {madrasas}
      handleClose={handleAddAnnouncementClose}
      onRefresh = {onRefresh}
      />
    
      <Typography component="div" variant="h5" sx={{fontSize:18, pb:2}} >Announcements</Typography>
      {announcements.map((data, index) => (
        <MadrasaTable 
        key={index}
        name={data.name}
        announcements={data.announcements}
        />
      ) )}
<div
className='fab-container'
>
<Fab variant="extended" color='primary' 
onClick={() => setAddAnnouncementOpen(true)}
>
  <AddIcon sx={{ mr: 1 }} />
  Add Announcement
</Fab>
</div>
    </div>
  )
}



 function MadrasaTable(props) {
  const {name} = props;
  const {announcements} = props;
  
  return (
    <div>
      <Typography sx={{pt:2, pb:1}} fontSize={18} color="text.secondary" component="div" variant='h6' >{name}</Typography>
    <TableContainer component={Paper} sx={{mb:10}} >
    <Table aria-label="announcements">
      <TableHead>
        <TableRow>
          <TableCell>Title</TableCell>
          <TableCell align="right">Description</TableCell>
          <TableCell align="right">Created At</TableCell>
         
        
        </TableRow>
      </TableHead>
      <TableBody>
        {announcements.map((row, index) => (
          <TableRow
            key={index}
            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
          >
            <TableCell component="th" scope="row">
              {row.title}
            </TableCell>
            <TableCell align="right">{row.description}</TableCell>
            <TableCell align="right">{row.created_at}</TableCell>
           
           
          </TableRow>
        ))}
      </TableBody>
    </Table>
  </TableContainer>
    </div>

  )
}
