import React, { useState } from 'react';
import { Card, Space, Button, Input,Form } from 'antd';
import { postRequest } from '../../utils/NetworkHandler';

const { TextArea } = Input;

function MasjiAnnouncements() {
  const [textValue, setTextValue] = useState("");

  const handleClick = () => {
    const data = { text: textValue };
    console.log(data);
    // replace postRequest with your actual function or import it from somewhere
    postRequest("/masjid/api/sendnotification", data, onSuccess, onError, true);
  };

  const onSuccess = (response) => {
    setTextValue('');
    alert("Notification Sent")
  };

  const onError = (error) => {
    alert(error.toString());
  };

  return (
    <Card title="Announcements" style={{ height: "300px", width:"600px" }}>
  <Form.Item label="Message">
    <TextArea value={textValue} onChange={(e) => setTextValue(e.target.value)} rows={4} />
  </Form.Item>
  <Form.Item>
    <Button onClick={handleClick}  type="primary">Send</Button>
  </Form.Item>
</Card>

  );
}

export default MasjiAnnouncements;
