import React, {useState} from 'react'
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import {Box} from "@mui/material";
import Slide from '@mui/material/Slide';
import { postRequest } from '../../../utils/NetworkHandler';

const Transition = React.forwardRef((props, ref) => <Slide direction="left" ref={ref} {...props} />);


export default function AddVolunteerDialog(props) {
    const [maxWidth, setMaxWidth] = React.useState('xs');
    const [fullWidth, setFullWidth] = React.useState(true);
    const [loading, setLoading] = useState(false);

    const [category, setCategory] = useState("");
    const [file, setfile] = useState("");


    const [categoriesHelper, setcategoriesHelper] = useState("");
    const [imageHelper, setImageHelper] = useState("");
    const requiredText = "This field is required";


    const saveAccount = () => {
        setLoading(true);
        let formValidated = true;
        if(category === ""){
            formValidated = false;
            setcategoriesHelper(requiredText);
        }
        if(file === ""){
            formValidated = false;
            setImageHelper(requiredText);
        }
        if(formValidated){
          
            const data = {'category': category, 'image': file}
            console.log(data);
            postRequest("/volunteer/api/addcategory", data, onSuccess, onError, true);
        }
        else{
            setLoading(false);
        }

    }

    const onSuccess = (response)=> {
        setLoading(false);

        const {data} = response;
        if(data.status){
            setCategory("");
            setfile("");
            handleClose();
            props.onRefresh();
        }
        else{
            const {message} = data;
            setcategoriesHelper(message);
        }
    }

    const onError = (error) => {
        setLoading(false);
        alert(error.toString());
    }

    const handleClose = () => {
        setcategoriesHelper("");
        setImageHelper("");
        props.handleClose();

    }

    const handleImageChange = (e) => {
      const file=e.target.files[0];
      
      const reader = new FileReader();
      console.log("next");
        
      reader.onload = function () {
          base64String = reader.result.replace("data:", "")
              .replace(/^.+,/, "");
    
          // imageBase64Stringsep = base64String;
    
          // alert(imageBase64Stringsep);
         setfile(base64String);
      }
      reader.readAsDataURL(file);
      // setfile(e[0].base64);

      // let newData= e.target.files[0];
      // setfile(newData);
  };

  let base64String = "";
   
  

  return (
    <div>
      
      <Dialog open={props.open} onClose={handleClose}
      maxWidth={maxWidth}
      fullWidth={fullWidth}
        TransitionComponent={Transition}
      >
        <DialogTitle>Add an Category</DialogTitle>
        <DialogContent>
         
         <Box sx={{display:"flex", gap:'10px'}} >
         <TextField
            error={categoriesHelper !== "" }
            helperText={categoriesHelper}
            value={category}
            onChange={(e) => setCategory(e.target.value)}
            autoFocus
            margin="dense"
            id="Category"
            label="Category Name"
            type="text"
            variant="standard"
          />
         </Box>

         <DialogContentText sx={{mt:5,mb:3}}>
            Please Uploade an image of 500 x 500px
          </DialogContentText>

         <Box sx={{display:"flex" , gap:'20px'}} >

         <input type="file" name="" id="fileId" 
        onChange={(e) => {handleImageChange(e)}} />
          
        </Box>

        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button disabled={loading}  onClick={() => saveAccount() }>Save</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}


