/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState, useEffect } from 'react';
import { Card, Button, Table, Select, DatePicker, Result, Modal, TimePicker, Form } from 'antd';
import { UploadOutlined, DownloadOutlined, EditOutlined } from '@ant-design/icons';
import axios from 'axios';
import dayjs from 'dayjs';
import 'dayjs/locale/en';
import { host, apptokenKey, getRequest } from '../../utils/NetworkHandler';

const { Option } = Select;

dayjs.locale('en');

const { RangePicker } = TimePicker;

const NamazTimings = () => {
  useEffect(() => {
    getData();
  }, []);

  const [file, setFile] = useState(null);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [editData, setEditData] = useState(null);
  const [namaztimings,setNamaztimings]=useState([]);
  const [filteredData, setFilteredData] = useState(namaztimings);
  const [filterMonth, setFilterMonth] = useState('');
  const [filterDate, setFilterDate] = useState('');
  const [isFilterApplied, setIsFilterApplied] = useState(false);


  const [form] = Form.useForm();

  const handleFileChange = (event) => {
    setFile(event.target.files[0]);
  };

  const handleUpload = () => {
    // Implement file upload functionality
    alert('Upload button clicked');
  };

  const handleDownload = () => {
    // Implement file download functionality
    alert('Download button clicked');
  };

  const handleFilterMonth = (value) => {
    setFilterMonth(value);
    filterData(value, filterDate);
  };
  
  const handleFilterDate = (date, dateString) => {
    setFilterDate(dateString);
    filterData(filterMonth, dateString);
  };
  
  

  const monthNames = [
    'January', 'February', 'March', 'April', 'May', 'June',
    'July', 'August', 'September', 'October', 'November', 'December'
  ];

  const handleSelectToday = () => {
    const today = new Date();
    const month = monthNames[today.getMonth()];
    const date = today.getDate().toString().padStart(2, '0');
    setFilterMonth(month);
    setFilterDate(date);
    filterData(month, date);
  };

  const handleResetFilters = () => {
    setFilterMonth('');
    setFilterDate(null);
    setFilteredData([]);
    setIsFilterApplied(false);
  };

  const handleEdit = (record) => {
    setEditData(record);
    setIsModalVisible(true);
  };

  const handleSave = () => {
    form.validateFields().then((values) => {
      // Update the data with the new prayer timings
      // You can handle the API call or data update here
      console.log('Updated Prayer Timings:', values);
      setIsModalVisible(false);
    });
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const filterData = (selectedMonth, selectedDate) => {
    const filtered = namaztimings.filter((item) => {
      if (selectedMonth && selectedDate) {
        const itemMonth = dayjs(item.date).format('MMMM');
        const itemDate = dayjs(item.date).format('DD');
        return itemMonth === selectedMonth && itemDate === selectedDate;
      } if (selectedMonth) {
        const itemMonth = dayjs(item.date).format('MMMM');
        return itemMonth === selectedMonth;
      } if (selectedDate) {
        const itemDate = dayjs(item.date).format('DD');
        return itemDate === selectedDate;
      }
      return true;
    });
  
    setFilteredData(filtered);
    setIsFilterApplied(filtered.length > 0);

    if (!selectedMonth && !selectedDate && filtered.length === 0) {
      const currentMonth = dayjs().format('MMMM');
      const currentDate = dayjs().format('DD');
      const currentMonthData = namaztimings.filter((item) => {
        const itemMonth = dayjs(item.date).format('MMMM');
        return itemMonth === currentMonth;
      });
      setFilteredData(currentMonthData);
      setIsFilterApplied(false);
      if (currentDate) {
        const todayData = currentMonthData.filter((item) => {
          const itemDate = dayjs(item.date).format('DD');
          return itemDate === currentDate;
        });
        setFilteredData(todayData);
        setIsFilterApplied(todayData.length > 0);
      }
    }
  };
  
  const getData = () => {
    getRequest('/masjid/api/gettimings', null, onSuccess, onError, true);
  };
  
  const onSuccess = (response) => {
    console.log(response.data);
    setNamaztimings(response.data);
  
    const currentMonth = dayjs().format('MMMM');
    const currentMonthData = response.data.filter((item) => {
      const itemMonth = dayjs(item.date).format('MMMM');
      return itemMonth === currentMonth;
    });
  
    setFilteredData(currentMonthData);
    setIsFilterApplied(false);
  };
  
  
  const onError = (error) => {
    alert(error.toString());
  };
  
  const columns = [
    { title: 'Date', dataIndex: 'date', key: 'date' },
    { title: 'Day', dataIndex: 'day', key: 'day' },
    { title: 'Fajr', dataIndex: 'fajar', key: 'fajar' },
    { title: 'Fajr Iqama', dataIndex: 'fajar_iqama', key: 'fajar_iqama' },
    { title: 'Sunrise', dataIndex: 'sunrise', key: 'sunrise' },
    { title: 'Zuhur', dataIndex: 'zuhur', key: 'zuhur' },
    { title: 'Zuhur Iqama', dataIndex: 'zuhur_iqama', key: 'zuhur_iqama' },
    { title: 'Asar', dataIndex: 'asar', key: 'asar' },
    { title: 'Asar Iqama', dataIndex: 'asar_iqama', key: 'asar_iqama' },
    { title: 'Maghrib', dataIndex: 'maghrib', key: 'maghrib' },
    { title: 'Maghrib Iqama', dataIndex: 'maghrib_iqama', key: 'maghrib_iqama' },
    { title: 'Isha', dataIndex: 'isha', key: 'isha' },
    { title: 'Isha Iqama', dataIndex: 'isha_iqama', key: 'isha_iqama' },
    { title: 'Juma', dataIndex: 'juma', key: 'juma' },
    {
      title: 'Actions',
      dataIndex: '',
      key: 'actions',
      render: (_, record) => (
        <Button type="link" onClick={() => handleEdit(record)}>
          <EditOutlined />
        </Button>
      ),
    },
  ];

  return (
    <Card
      title="Namaz Timings"
      extra={
        <>
          <input type="file" style={{ display: 'none' }} onChange={handleFileChange} />
          <Button type="primary" onClick={handleUpload} icon={<UploadOutlined />} style={{ marginRight: 10 }}>
            Upload
          </Button>
          <Button type="primary" onClick={handleDownload} icon={<DownloadOutlined />}>
            Download
          </Button>
        </>
      }
    >
      <div style={{ display: 'flex', marginBottom: 16, alignItems: 'center' }}>
        <label htmlFor="filterMonth" style={{ marginRight: 8 }}>
          Filter by Month:
        </label>
        <Select id="filterMonth" value={filterMonth} style={{ width: 120, marginRight: 8 }} onChange={handleFilterMonth}>
          <Option value="">All</Option>
          <Option value="January">January</Option>
          <Option value="February">February</Option>
          {/* Add more options for other months */}
        </Select>
        <label htmlFor="filterDate" style={{ marginRight: 8 }}>
          Filter by Date:
        </label>
        <DatePicker id="filterDate" style={{ width: 120, marginRight: 8 }} onChange={handleFilterDate} />
        <Button type="primary" onClick={handleSelectToday} style={{ marginRight: 8 }}>
          Today
        </Button>
        <Button onClick={handleResetFilters}>Reset Filters</Button>
      </div>
      {isFilterApplied ? (
        <Table dataSource={filteredData} columns={columns} />
      ) : (
        <Result status="info" title="No Data Found" />
      )}

      <Modal
        title="Edit Prayer Timings"
        visible={isModalVisible}
        onOk={handleSave}
        onCancel={handleCancel}
        width={600}
      >
        <Form form={form} initialValues={editData}>
          <Form.Item name="fajar" label="Fajr" rules={[{ required: true, message: 'Please select Fajr timing' }]}>
            <TimePicker format="HH:mm:ss" />
          </Form.Item>
          <Form.Item name="fajar_iqama" label="Fajr Iqama" rules={[{ required: true, message: 'Please select Fajr Iqama timing' }]}>
            <TimePicker format="HH:mm:ss" />
          </Form.Item>
          <Form.Item name="sunrise" label="Sunrise" rules={[{ required: true, message: 'Please select Sunrise timing' }]}>
            <TimePicker format="HH:mm:ss" />
          </Form.Item>
          <Form.Item name="zuhur" label="Zuhur" rules={[{ required: true, message: 'Please select Zuhur timing' }]}>
            <TimePicker format="HH:mm:ss" />
          </Form.Item>
          <Form.Item name="zuhur_iqama" label="Zuhur Iqama" rules={[{ required: true, message: 'Please select Zuhur Iqama timing' }]}>
            <TimePicker format="HH:mm:ss" />
          </Form.Item>
          <Form.Item name="asar" label="Asar" rules={[{ required: true, message: 'Please select Asar timing' }]}>
            <TimePicker format="HH:mm:ss" />
          </Form.Item>
          <Form.Item name="asar_iqama" label="Asar Iqama" rules={[{ required: true, message: 'Please select Asar Iqama timing' }]}>
            <TimePicker format="HH:mm:ss" />
          </Form.Item>
          <Form.Item name="maghrib" label="Maghrib" rules={[{ required: true, message: 'Please select Maghrib timing' }]}>
            <TimePicker format="HH:mm:ss" />
          </Form.Item>
          <Form.Item name="maghrib_iqama" label="Maghrib Iqama" rules={[{ required: true, message: 'Please select Maghrib Iqama timing' }]}>
            <TimePicker format="HH:mm:ss" />
          </Form.Item>
          <Form.Item name="isha" label="Isha" rules={[{ required: true, message: 'Please select Isha timing' }]}>
            <TimePicker format="HH:mm:ss" />
          </Form.Item>
          <Form.Item name="isha_iqama" label="Isha Iqama" rules={[{ required: true, message: 'Please select Isha Iqama timing' }]}>
            <TimePicker format="HH:mm:ss" />
          </Form.Item>
        </Form>
      </Modal>
    </Card>
  );
};

export default NamazTimings;
