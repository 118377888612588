/* eslint-disable jsx-a11y/alt-text */
import React, {useState, useEffect} from 'react'
import Box from '@mui/material/Box';
import Fab from '@mui/material/Fab';
import AddIcon from '@mui/icons-material/Add';

import RotateLeftIcon from '@mui/icons-material/RotateLeft';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import {IconButton, Typography} from "@mui/material"; 

import EditIcon from '@mui/icons-material/Edit';
import {host,getRequest, postRequest} from "../../../utils/NetworkHandler";
import AddActivity from './AddDialog';
import EditActivityDialog from './EditActivityDialog';


export default function ActivityCategories() {

  const onRefresh = () => {
    getData();
  };

  useEffect( () => {
    getData();
  },  [])

  const handleAddAccountClose = () =>{
    setaddCategoryOpen(false);
  }

  const handleResetPasswordClose = () =>{
    setCategoryOpen(false);
  }

  const [category, setCategory] = useState([]);

  const onSuccess = (response) => {
    // console.log(response.data)
    const {data} = response
    setCategory(data)
  }

  const onError = (error) => {
    alert(error.toString())
  }

  const getData = () => {
      getRequest("/activity/api/getWebcategory", null, onSuccess, onError, true);
  }

   
  const [addCategoryOpen, setaddCategoryOpen] = useState(false);
  const [editCategoryOpen, setCategoryOpen] = useState(false);
  const [categoryEdit, setCategoryEdit] = useState("-1");
  return (
    <div>
      <AddActivity
      open={addCategoryOpen}
      handleClose={handleAddAccountClose}
      onRefresh = {onRefresh}
      />
        <EditActivityDialog
      open={editCategoryOpen}
      handleClose={handleResetPasswordClose}
      editobj={categoryEdit}
      onRefresh = {onRefresh}
/>
      <Typography component="div" variant="h5" sx={{fontSize:18, pb:2}} >Men & Women Categores</Typography>
      <TableContainer component={Paper} sx={{mb:10}} >
      <Table aria-label="Accounts">
        <TableHead>
          <TableRow>
            <TableCell align="left">Category Name</TableCell>
            <TableCell align="left">Image</TableCell>
            <TableCell align="right">Edit</TableCell>
          
          </TableRow>
        </TableHead>
        <TableBody>
          {category.map((row, index) => (
            <TableRow
              key={index}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell align='left'>  
                {row.activity_type}
              </TableCell>
              <TableCell align='left'><img width={100} height={100} src={host + row.type_image}/></TableCell>
              <TableCell align="right">
                <IconButton 
                onClick={() => {
                  setCategoryOpen(true);
                  setCategoryEdit(row);
                }}
                >
                <EditIcon />
                </IconButton></TableCell>
             
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>

<div
className='fab-container'
>
<Fab variant="extended" color='primary' 
onClick={() => setaddCategoryOpen(true)}
>
  <AddIcon sx={{ mr: 1 }} />
  Add Category
</Fab>
</div>
    </div>
  )
}
