import React, {useState} from 'react'
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import {Box} from "@mui/material";
import Slide from '@mui/material/Slide';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import PhoneInput from "react-phone-input-2";
import { postRequest } from '../../utils/NetworkHandler';
import "react-phone-input-2/lib/bootstrap.css"

const Transition = React.forwardRef((props, ref) => <Slide direction="left" ref={ref} {...props} />);


export default function AddTeacherialog(props) {
    const [maxWidth, setMaxWidth] = React.useState('xs');
    const [fullWidth, setFullWidth] = React.useState(true);
    const [loading, setLoading] = useState(false);

    const [username, setUserName] = useState("");
    const [name, setName]=useState("");
    const [email, setEmail] = useState("");

    const [fullNameHelper, setFullNameHelper] = useState("");
    const [descHelper, setDescHelper]=useState("");
   
    const [usernameHelper, setUserNameHelper] = useState("");
    const {madrasas} = props;
    const [selected, setSelected] = useState("-1");
  
    const requiredText = "This field is required";

    const saveAccount = () => {
        setLoading(true);
        let formValidated = true;
        if(username === ""){
            formValidated = false;
            setUserNameHelper(requiredText);
        }

        if(selected === "-1"){
          formValidated = false;
          alert("Please select a madrasa");
        }
       

        if(formValidated){
            const data = {'username': username, 'name':name, 'email': email, "madrasa_id": selected}
            postRequest("/landing/api/ummah/add-teacher", data, onSuccess, onError, true);
        }
        else{
            setLoading(false);
        }

    }

    const onSuccess = (response)=> {
        setLoading(false);

        const {data} = response;
        if(data.status){
            setUserName("");
          setSelected("-1");
          setName("");
          setEmail("");
            handleClose();
            props.onRefresh();
        }
        else{
            const {message} = data;
            setUserNameHelper(message);
        }
    }

    const onError = (error) => {
        setLoading(false);
        alert(error.toString());
    }

    const handleClose = () => {
        setUserNameHelper("");
        props.handleClose();

    }

    const handleMadrasaChange = (e) => {
      setSelected(e.target.value);
    }
    

  return (
    <div>
      
      <Dialog open={props.open} onClose={handleClose}
      maxWidth={maxWidth}
      fullWidth={fullWidth}
        TransitionComponent={Transition}
      >
        <DialogTitle>Add a Teacher</DialogTitle>
        <DialogContent>
          <DialogContentText>
            This action cannot be undone, please enter details with caution.
          </DialogContentText>
         <Box sx={{display:"flex", gap:'10px'}} >
         {/* <TextField
            error={usernameHelper !== "" ? true: false }
            helperText={usernameHelper}
            value={username}
            onChange={(e) => setUserName(e.target.value)}
            autoFocus
            margin="dense"
            id="username"
            label="Phone Number"
            type="text"
            variant="standard"
          /> */}
        <PhoneInput
            country={"fi"}
            enableSearch
            value={username}
            onChange={(value) => setUserName(`+${  value}`)}
          />  
        
         </Box>


         <Box sx={{display:"flex", gap:'10px'}} >

         <TextField
 fullWidth
            rows={1}
            autoFocus
            margin="dense"
            id="name"
            label="Name"
            type="text"
            // error={name !== "" ? true: false }
            helperText={descHelper}
            value={name}
            onChange={(e) => setName(e.target.value)}
            variant="standard"
          />


          <TextField
 fullWidth
            rows={2}
            autoFocus
            margin="dense"
            id="name"
            label="Email"
            type="text"
            // error={name !== "" ? true: false }
            helperText={descHelper}
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            variant="standard"
          />
         </Box>

         <FormControl
      sx={{mt:2}}
      fullWidth>
        <InputLabel id="assign-to-label">Madrasa</InputLabel>
        <Select
          labelId="assign-to-label"
          id="assign-to-select"
          value={selected}
          label="Assign To"
          onChange={handleMadrasaChange}
        >
          <MenuItem value={"-1"}>Select a madrasa</MenuItem>
          {madrasas.map(( item, index) => (
          <MenuItem
          key={index}
          value={item.id}> {item.name}</MenuItem>
          ) )}
        </Select>
      </FormControl>


        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button disabled={loading}  onClick={() => saveAccount() }>Save</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}


