import React, {useState, useEffect} from 'react'


import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import {IconButton, Typography} from "@mui/material"; 
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import DoneIcon from '@mui/icons-material/Done';
import CloseIcon from '@mui/icons-material/Close';
import AssignTeacherDialog from './AssignTeacherDialog';
import {getRequest, postRequest} from "../../utils/NetworkHandler";
import RejectDialog from './RejectDialog';

export default function Enrollments() {

  useEffect( () => {
    getData();
  },  [])

  


  const onRefresh = () => {
    getData();
  }
  const [madrasas, setMadrasas] = useState([]);

  const onSuccess = (response) => {
    const {data} = response;
    console.log(madrasas)
    setMadrasas(data.madrasas);
  }

  const onError = (error) => {
    alert(error.toString())
  }

  const getData = () => {
      getRequest("/landing/api/ummah/get-enrollments", null, onSuccess, onError, true);
  }


  return (
    <div>
     
    
      <Typography component="div" variant="h5" sx={{fontSize:18, pb:2}} >Pending Enrollments</Typography>
      {madrasas.map((data, index) => (
        <MadrasaTable 
        key={index}
        onRefresh={onRefresh}
        name={data.name}
        pendingEnrolls={data.pending_enrolls}
        teachers={data.teachers}

        />
      ) )}
    </div>
  )
}



 function MadrasaTable(props) {
  const {name} = props;
  const {pendingEnrolls} = props;
  const {teachers} = props;
  const [open, setOpen] = useState(false);
  const [enrollId, setEnrollId] = useState("-1");
  const [cancel, setcancel] = useState(false);
  
  return (
    <div>
      <AssignTeacherDialog 
        open={open}
        handleClose={() => {
          setOpen(false);
        }}
        teachers = {teachers}
        onRefresh ={props.onRefresh}
        studentId={enrollId}


      />
       <RejectDialog 
        open={cancel}
        handleClose={() => {
          setcancel(false);
        }}
        teachers = {teachers}
        onRefresh ={props.onRefresh}
        studentId={enrollId}


      />
      <Typography sx={{pt:2, pb:1}} fontSize={18} color="text.secondary" component="div" variant='h6' >{name}</Typography>
    <TableContainer component={Paper} sx={{mb:10}} >
    <Table aria-label="Accounts">
      <TableHead>
        <TableRow>
          <TableCell>Student Name</TableCell>
          <TableCell align="right">Parent Name</TableCell>
          <TableCell align="right">Contact Number</TableCell>
          <TableCell align="right">Selected Batch</TableCell>
          <TableCell align="right">Approve</TableCell>
          <TableCell align="right">Reject</TableCell>

         
        
        </TableRow>
      </TableHead>
      <TableBody>
        {pendingEnrolls.map((row, index) => (
          <TableRow
            key={index}
            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
          >
            <TableCell component="th" scope="row">
              {row.name}
            </TableCell>
            <TableCell align="right">{row.parent_name}</TableCell>
            <TableCell align="right">{row.emergency_contact}</TableCell>
            <TableCell align="right">{row.batch.batch}</TableCell>
            <TableCell align="right">
            <IconButton>
            <DoneIcon 
                onClick={() => {
                  setOpen(true);
                  setEnrollId(row.id);
                }}
                color="primary">
                <ArrowRightAltIcon />
                </DoneIcon>
            </IconButton>
            </TableCell>
            <TableCell align="right">
            <IconButton>
            <CloseIcon 
                onClick={() => {
                  setcancel(true);
                  setEnrollId(row.id);
                }}
                color="error">
                <ArrowRightAltIcon />
                </CloseIcon>
            </IconButton>
            </TableCell>



           
           
          </TableRow>
        ))}
      </TableBody>
    </Table>
  </TableContainer>
    </div>

  )
}
