// components
import SvgIconStyle from '../../../components/SvgIconStyle';

// ----------------------------------------------------------------------

const getIcon = (name) => <SvgIconStyle src={`/icons/${name}.svg`} sx={{ width: 1, height: 1 }} />;

const ICONS = {
  user: getIcon('ic_user'),
  ecommerce: getIcon('ic_ecommerce'),
  analytics: getIcon('ic_analytics'),
  dashboard: getIcon('ic_dashboard'),
  volunteer:getIcon('volunteer'),
  job:getIcon('job'),
  activity:getIcon('activity'),
  mapping:getIcon('mapping'),
  form:getIcon('form'),
  madrasa:getIcon('madrasa'),
  announcement:getIcon('announcement'),
};

const sidebarConfig = [
  // GENERAL
  // ----------------------------------------------------------------------
  {
    subheader: 'Admin',
    items: [
      { title: 'Dashboard', path: '/admin/dashboard', icon: ICONS.analytics },
    ],
    
  },
  {
    subheader: 'Madrasas',
    items: [
      { path: "/admin/madrasas", title: "My Madrasas", icon:ICONS.madrasa },
      { path: "/admin/teachers", title: "Teachers", icon: ICONS.user },
      { path: "/admin/announcements", title: "Announcements", icon: ICONS.announcement },
      { path: "/admin/enrollments", title: "Enrollments", icon: ICONS.form },
      { path: "/admin/payments", title: "Pending Payments", icon: ICONS.ecommerce },
      { path: "/admin/teachermapping", title: "Teacher Mapping", icon: ICONS.mapping },
    ],
    
  },
];

export default sidebarConfig;
