/* eslint-disable */
import React, {useState} from 'react'
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import {Box} from "@mui/material";
import Slide from '@mui/material/Slide';
import { postRequest } from '../../../utils/NetworkHandler';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="left" ref={ref} {...props} />;
});


export default function AddAccountDialog(props) {
    const [maxWidth, setMaxWidth] = React.useState('xs');
    const [fullWidth, setFullWidth] = React.useState(true);
    const [loading, setLoading] = useState(false);

    const [username, setUserName] = useState("");
    const [fullName, setFullName] = useState("");
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");

    const [usernameHelper, setUserNameHelper] = useState("");
    const [fullNameHelper, setFullNameHelper] = useState("");
    const [emailHelper, setEmailHelper] = useState("");
    const [passwordHelper, setPasswordHelper] = useState("");
    let requiredText = "This field is required";

    const validateEmail = (email) => {
        return String(email)
          .toLowerCase()
          .match(
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          );
      };

    const saveAccount = () => {
        setLoading(true);
        let formValidated = true;
        if(username === ""){
            formValidated = false;
            setUserNameHelper(requiredText);
        }
        if(fullName === ""){
            formValidated = false;
            setFullNameHelper(requiredText);
        }
        if (email === ""){
            formValidated = false;
            setEmailHelper(requiredText);
        }
        if(password === ""){
            formValidated = false;
            setPasswordHelper(requiredText);
        }

        if( !validateEmail(email) ){
            formValidated = false;
            setEmailHelper("Please enter a valid email address");
        }

        if(formValidated){
            var data = {'username': username, 'email': email, 'password': password, 'fullname': fullName}
            postRequest("/landing/api/add-account", data, onSuccess, onError, true);
        }
        else{
            setLoading(false);
        }

    }

    const onSuccess = (response)=> {
        setLoading(false);

        var data = response.data;
        if(data.status){
            setUserName("");
            setPassword("");
            setEmail("");
            setFullName("");
            handleClose();
            props.onRefresh();
        }
        else{
            let message = data.message;
            setUserNameHelper(message);
        }
    }

    const onError = (error) => {
        setLoading(false);
        alert(error.toString());
    }

    const handleClose = () => {
        setUserNameHelper("");
        setEmailHelper("");
        setPasswordHelper("");
        setFullNameHelper("");
        props.handleClose();

    }
    

  return (
    <div>
      
      <Dialog open={props.open} onClose={handleClose}
      maxWidth={maxWidth}
      fullWidth={fullWidth}
        TransitionComponent={Transition}
      >
        <DialogTitle>Add an Account</DialogTitle>
        <DialogContent>
          <DialogContentText>
            This action cannot be undone, please enter details with caution.
          </DialogContentText>
         <Box sx={{display:"flex", gap:'10px'}} >
         <TextField
            error={usernameHelper !== "" ? true: false }
            helperText={usernameHelper}
            value={username}
            onChange={(e) => setUserName(e.target.value)}
            autoFocus
            margin="dense"
            id="username"
            label="Username"
            type="text"
            variant="standard"
          />
           <TextField
            autoFocus
            margin="dense"
            id="email"
            label="Email"
            type="email"
            error={emailHelper !== "" ? true: false }
            helperText={emailHelper}
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            variant="standard"
          />
         </Box>

         <Box sx={{display:"flex", gap:'10px'}} >

         <TextField
            autoFocus
            margin="dense"
            id="name"
            label="Full Name"
            type="text"
            error={fullNameHelper !== "" ? true: false }
            helperText={fullNameHelper}
            value={fullName}
            onChange={(e) => setFullName(e.target.value)}
            variant="standard"
          />
          
          <TextField
            autoFocus
            error={passwordHelper !== "" ? true: false }
            helperText={passwordHelper}
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            margin="dense"
            id="password"
            label="Password"
            type="password"
            variant="standard"
          />
        </Box>

        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button disabled={loading}  onClick={() => saveAccount() }>Save</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}


