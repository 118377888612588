/* eslint-disable no-var */
import React, {useState, useEffect} from 'react'


import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import {IconButton, Typography} from "@mui/material"; 
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
// import AssignTeacherDialog from '../admin/AssignTeacherDialog';
import Button from '@mui/material/Button';
import { ApprovalRounded } from '@mui/icons-material';
import {getRequest, postRequest} from "../../../utils/NetworkHandler";

export default function Dawaah() {

  useEffect( () => {
    getData();
  },  [])

  const onRefresh = () => {
    getData();
  }
  const[data,setData]=useState([])
  const [name, setName] = useState([]);
  const [need, setNeed] = useState([]);
  const [username, setUsername] = useState([]);
  const [category, setCategory] = useState([]);

  const onSuccess = (response) => {
    setData(response.data)
    // var data = response.data;
    // console.log(get)
    // [get].map((iteams,index)=>{
    //        data=iteams[index]
    // })
    // console.log(get[1].name)
  }

  const onError = (error) => {
    alert(error.toString())
  }

  const getData = () => {
      getRequest("/dawaah/api/validate-DawaahValidation", null, onSuccess, onError, true);
  }

  const onapproveSuccess=(response)=>{
    onRefresh();
  }

  const approve = (id) => {
    var data={"id":id}
    postRequest("/dawaah/api/validate-DawaahValidation", data, onapproveSuccess, onError, true);
  }

  return (
    <div>
        
      <Typography component="div" variant="h5" sx={{fontSize:18, pb:2}} >Pending Approval</Typography>

            <Typography sx={{pt:2, pb:1}} fontSize={18} color="text.secondary" component="div" variant='h6' >{name}</Typography>
    <TableContainer component={Paper} sx={{mb:10}} >
    <Table aria-label="Volunteer">
      <TableHead>
        <TableRow>
          <TableCell>Host</TableCell>
          <TableCell align="right">Topic</TableCell>
          <TableCell align="center">Description</TableCell>
          <TableCell align="right">Type</TableCell>
          <TableCell align="center">Status</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
      {data.map((iteams,index)=>(
        <TableRow
            key={index}
            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
          >
            
            <TableCell>{iteams.hosted_by}</TableCell>
            <TableCell  align="right" scope="row">
             {iteams.topic}
            </TableCell>
            <TableCell  align="center" scope="row">
             {iteams.description}
            </TableCell>
            <TableCell  align="right" scope="row">
             {iteams.dawaah_type}
            </TableCell>
            <TableCell align="center">
            <Button onClick={() => approve(iteams.id)} variant="contained">Approve</Button>
            </TableCell>
           
          </TableRow>
        ))}
        
      </TableBody>
    </Table>
  </TableContainer>

    </div>
  )
}

