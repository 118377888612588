/* eslint-disable */
import React, {useEffect, useState} from 'react'
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import {Box} from "@mui/material";
import Slide from '@mui/material/Slide';
import { postRequest } from '../../../utils/NetworkHandler';
// import BatchCheckbox from '../../components/BatchCheckbox';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Batch from '../../../components/Batch';
// import { BatchList } from './Batch';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="left" ref={ref} {...props} />;
});


export default function AddMadrasaDialog(props) {
    const [maxWidth, setMaxWidth] = React.useState('xs');
    const [desc, setDescName]=useState("");
    const [fullWidth, setFullWidth] = React.useState(true);
    const [loading, setLoading] = useState(false);

    const [fullName, setFullName] = useState("");
    const [fee, setFee] = useState("");
    const [address, setAddress] = useState("");
    const [SampleData, setSampleData] = useState([{ service: "" }]);

    const [fullNameHelper, setFullNameHelper] = useState("");
    const [descHelper, setDescHelper]=useState("");
    const [feeHelper, setFeeHelper] = useState("");
    const [addressHelper, setAddressHelper] = useState("");
    const [assignTo, setAssignTo] = useState("-1");
  

    const batches = props.batches;
    const accounts = props.accounts;

    const [batchData, setBatchData] = useState([]);

    function createBatchdata(id, checked, batch){
      return {id, checked, batch};
    }
    

    useEffect( () => {
     if(batches !== null && batches !== undefined && batches.length > 0){
      var batchArr = [];
      for(let i =0; i< batches.length; i++){
        let batch = batches[i];
        let data = createBatchdata(batch.id, true, batch.batch);
        batchArr.push(data);
      }
      setBatchData(batchArr);
     }
    }, [batches,] );

    let requiredText = "This field is required";

    const handleBatchCheckChange = (index, event) => {
      let data = [...batchData];
      let editRow = data[index];
      editRow.checked = event.target.checked;
      data[index] = editRow;
      setBatchData(data);

    }


    const saveAccount = () => {
        setLoading(true);
        let formValidated = true;
       
        if(fullName === ""){
            formValidated = false;
            setFullNameHelper(requiredText);
        }
        if(address === ""){
          formValidated = false;
          setAddressHelper(requiredText);
        }

        if(desc === ""){
          formValidated = false;
          setDescHelper(requiredText);
      }
        
       
        if(isNaN(fee) || fee === "" ){
          formValidated = false;
          setFeeHelper("Please eneter a valid number");
        }
        
        let checked = false;
        for(let i =0; i< batchData.length; i++){
          let batch = batchData[i];
          if(batch.checked){
            checked = true;
          }
        }

        if (!checked){
          formValidated = false;
          alert("Please select at least one batch");
        }

        if(assignTo === "-1"){
          formValidated = false;
          alert("Please select whom to assign the madrasa to")
        }
        if(SampleData === ""){
          formValidated = false;
          alert("Enter at least one batch")
        }

        if(formValidated){
            var data = {'madrasa_name': fullName, 'descripton':desc, 'fee': fee, 'address': address, 'batches': SampleData, 'assigned_to': assignTo}
            postRequest("/landing/api/add-madrasa", data, onSuccess, onError, true);
        }
        else{
            setLoading(false);
        }

    }

    const onSuccess = (response)=> {
        setLoading(false);

        var data = response.data;
        if(data.status){
          
            setFullName("");
            setAssignTo("-1");
            setAddress("");
            setFee("");
            handleClose();
            props.onRefresh();
            setSampleData("");
            setDescName("");
        }
        else{
           
        }
    }

    const onError = (error) => {
        setLoading(false);
        alert(error.toString());
    }

    const handleClose = () => {
      
        setFullNameHelper("");
        setFeeHelper("");
        setAddressHelper("");
        setAssignTo("-1");
        props.handleClose();

    }

    const handleAssignToChange = (e) => {
      setAssignTo(e.target.value);
    }
    

  return (
    <div>
      
      <Dialog open={props.open} onClose={handleClose}
      maxWidth={maxWidth}
      fullWidth={fullWidth}
        TransitionComponent={Transition}
      >
        <DialogTitle>Add a madrasa</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Please fill in this form to proceed.
          </DialogContentText>
         <Box sx={{display:"flex", gap:'10px'}} >
         
         </Box>

         <Box sx={{display:"flex", gap:'10px'}} >

         <TextField
            autoFocus
            margin="dense"
            id="name"
            label="Madrasa Name"
            type="text"
            error={fullNameHelper !== "" ? true: false }
            helperText={fullNameHelper}
            value={fullName}
            onChange={(e) => setFullName(e.target.value)}
            variant="standard"
          />

<TextField
            autoFocus
            margin="dense"
            id="fee"
            label="Madrasa Fee"
            type="number"
            error={feeHelper !== "" ? true: false }
            helperText={feeHelper}
            value={fee}
            onChange={(e) => setFee(e.target.value)}
            variant="standard"
          />
         
        </Box>

        <Box sx={{display:"flex", gap:'10px'}} >
        <TextField
            autoFocus
            margin="dense"
            id="addresss"
            label="Address"
            type="text"
            multiline
            error={addressHelper !== "" ? true: false }
            helperText={addressHelper}
            value={address}
          rows={2}

            onChange={(e) => setAddress(e.target.value)}
            variant="standard"
          />
          <TextField
            rows={2}
            multiline
            autoFocus
            margin="dense"
            id="desc"
            label="Description"
            type="text"
            error={descHelper !== "" ? true: false }
            helperText={descHelper}
            value={desc}
            onChange={(e) => setDescName(e.target.value)}
            variant="standard"
          />
          </Box>
          <FormControl
      sx={{mt:2}}
      fullWidth>
        <InputLabel id="assign-to-label">Assign To</InputLabel>
        <Select
          labelId="assign-to-label"
          id="assign-to-select"
          value={assignTo}
          label="Assign To"
          onChange={handleAssignToChange}
        >
          <MenuItem value={"-1"}>Select Account</MenuItem>
          {accounts.map(( item, index) => (
          <MenuItem
          key={index}
          value={item.id}> {item.username} - {item.profile.name}</MenuItem>
          ) )}
        </Select>
      </FormControl>
          {/* <BatchCheckbox 
          batchData = {batchData}
          handleChange = {handleBatchCheckChange}
          /> */}
      <Batch setSampleData={setSampleData}/>
       </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button disabled={loading}  onClick={() => saveAccount() }>Save</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}


