import React, {useState, useEffect} from 'react'
import Box from '@mui/material/Box';
import Fab from '@mui/material/Fab';
import AddIcon from '@mui/icons-material/Add';
import {Typography, IconButton} from "@mui/material";

import EditIcon from '@mui/icons-material/Edit';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import {getRequest} from "../../utils/NetworkHandler";

import AddMadrasaDialog from "./AddMadrasaDialog";
import EditMadrasaDialog from "./EditMadrasaDialog";

export default function Madrasas() {

  useEffect( () => {
    getData();
  },  [])

  const handleAddMadrasaClose = () =>{
    setAddMadrasaOpen(false);
  }

  const handleEditMadrasaClose = () =>{
    setEditMadrasaOpen(false);
  }

  const onRefresh = () => {
    getData();
  }
  const [madrasas, setMadrasas] = useState([]);

  const onSuccess = (response) => {
    const {data} = response;
    // console.log(data)
    setMadrasas(data.madrasas);
    setBatches(data.batches);
  }

  const onError = (error) => {
    alert(error.toString())
  }

  const getData = () => {
      getRequest("/landing/api/ummah/get-madrasas-init", null, onSuccess, onError,true);
  }

   
  const [addMadrasaOpen, setAddMadrasaOpen] = useState(false);
  const [editMadrasaOpen, setEditMadrasaOpen] = useState(false);
  const [editmadrasa, setEditmadrasa] = useState("-1");
  const [batches, setBatches] = useState([]);
  
  // console.log(madrasas);

  return (
    <div>

<AddMadrasaDialog
      open={addMadrasaOpen}
      handleClose={handleAddMadrasaClose}
      batches={batches}
      onRefresh = {onRefresh}
      />
        <EditMadrasaDialog
      open={editMadrasaOpen}
      handleClose={handleEditMadrasaClose}
      editObj={editmadrasa}
      batches={batches}
      onRefresh = {onRefresh}
/>
      
      <Typography component="div" variant="h5" sx={{fontSize:18, pb:2}} >Madrasas</Typography>

      <TableContainer component={Paper}
      sx={{mb:10}}
      >
      <Table aria-label="Accounts">
        <TableHead>
          <TableRow>
            <TableCell>Madrasa Name</TableCell>
            <TableCell align="right">Description</TableCell>
            <TableCell align="right">Account Name</TableCell>
            <TableCell align="right">Location</TableCell>
            <TableCell align="right">Actions</TableCell>


          
          </TableRow>
        </TableHead>
        <TableBody>
          {madrasas.map((row, index) => (
            <TableRow
              key={index}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell component="th" scope="row">
                {row.name}
              </TableCell>
              
              <TableCell align='right'>{row.describtion}</TableCell>
              <TableCell align="right">{row.assigned_to.username} - {row.assigned_to.profile.name} </TableCell>
              <TableCell align="right">{row.address}</TableCell>
              <TableCell align="right">
                <IconButton 
                onClick={() => {
                  setEditMadrasaOpen(true);
                  setEditmadrasa(row);
                }}
                color="primary" >
                <EditIcon />
                </IconButton></TableCell>
             
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>


      <div
className='fab-container'
>
<Fab variant="extended" color='primary' 
onClick={() => setAddMadrasaOpen(true)}
>
  <AddIcon sx={{ mr: 1 }} />
  Add Madrasa
</Fab>
</div>

    </div>
  )
}
