/* eslint-disable */
import React, {useState} from 'react'
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import {Box} from "@mui/material";
import Slide from '@mui/material/Slide';
import { postRequest } from '../../../utils/NetworkHandler';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="left" ref={ref} {...props} />;
});


export default function ResetPasswordDialog(props) {
    const [maxWidth, setMaxWidth] = React.useState('xs');
    const [fullWidth, setFullWidth] = React.useState(true);
    const [loading, setLoading] = useState(false);

    const [password, setPassword] = useState("");

    const [passwordHelper, setPasswordHelper] = useState("");
    let requiredText = "This field is required";

   

    const resetPassword = () => {
        setLoading(true);
        let formValidated = true;
       
        if(password === ""){
            formValidated = false;
            setPasswordHelper(requiredText);
        }

     

        if(formValidated){
            var data = { 'password': password, 'account_id': props.accountID}
            postRequest("/landing/api/reset-password", data, onSuccess, onError, true);
        }
        else{
            setLoading(false);
        }

    }

    const onSuccess = (response)=> {
        setLoading(false);

        var data = response.data;
        if(data.status){
            setPassword("");
            handleClose();
        }
        else{
            let message = data.message;
            setPasswordHelper(message);
        }
    }

    const onError = (error) => {
        setLoading(false);
        alert(error.toString());
    }

    const handleClose = () => {
        setPasswordHelper("");
        props.handleClose();

    }
    

  return (
    <div>
      
      <Dialog open={props.open} onClose={handleClose}
      maxWidth={maxWidth}
      fullWidth={fullWidth}
        TransitionComponent={Transition}
      >
        <DialogTitle>Reset Password</DialogTitle>
        <DialogContent>
        
    
        <TextField
            autoFocus
            error={passwordHelper !== "" ? true: false }
            helperText={passwordHelper}
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            margin="dense"
            id="password"
            label="Password"
            type="password"
            fullWidth
            variant="standard"
          />

        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button disabled={loading}  onClick={() => resetPassword() }>Reset</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}


