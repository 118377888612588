/* eslint-disable*/

import { useEffect, useState } from "react";
import "./App.css";

  function EditBatch(props) {
    const [BatchList, setBatchList] = useState([{}]);



  

    useEffect(()=>{
      const fetchdata=()=>{
        var result=[];
        const filtterdata=props.batchData;
        filtterdata.map((data,index)=>{
         console.log(data.checked)
         if(data.checked===true)
         {
            result.push(data) 
         }
        })
        setBatchList(result)
        props.setSampleData(result)
      }
      fetchdata();   
     },[props.batchData]);

      const handleServiceChange = (e, index) => {
        console.log(e,index);
        const { name, value } = e.target;
        const list = [...BatchList];
        list[index][name] = value;
        setBatchList(list);
        props.setSampleData(list);
        // console.log(BatchList);  
      };
    
      const handleServiceRemove = (index) => {
        const list = [...BatchList];
        list.splice(index, 1);
        setBatchList(list);
        props.setSampleData(list);
      };
    
      const handleServiceAdd = () => {
        setBatchList([...BatchList, { service: "" }]);
      }; 
    
      return (
        <form className="App" autoComplete="off">
          <div className="form-field">
            <label htmlFor="service">Batches</label>
            {BatchList.map((singleService, index) => (
              <div key={index} className="services">
                <div className="first-division">
                  <input
                    name="batch"
                    type="text"
                    id="batch"
                    value={singleService.batch}
                    onChange={(e) => handleServiceChange(e, index)}
                    required
                  />
                  {BatchList.length - 1 === index && BatchList.length < 10 && (
                    <button
                      type="button"
                      onClick={handleServiceAdd}
                      className="add-btn"
                    >
                      <span>Add a Batch</span>
                    </button>
                  )}
                </div>
                <div className="second-division">
                  {BatchList.length !== 1 && (
                    <button
                      type="button"
                      onClick={() => handleServiceRemove(index)}
                      className="remove-btn"
                    >
                      <span>Remove</span>
                    </button>
                  )}
                </div>
              </div>
            ))}
          </div>
          {/* <div className="output">
            <h2>Entered Batches</h2>
            {BatchList &&
              BatchList.map((singleService, index) => (
                <ul key={index}>
                  {singleService.batch && <li>{singleService.batch}</li>}
                </ul>
              ))}
          </div> */}
        </form>
      );
    }
  
  export default EditBatch;