/* eslint-disable jsx-a11y/label-has-associated-control */
import { useState } from "react";
import "./App.css";

export const BatchList=[];

function Batch(props) {
    const [BatchList, setBatchList] = useState([{ service: "" }]);
  
    const handleServiceChange = (e, index) => {
      const { name, value } = e.target;
      const list = [...BatchList];
      list[index][name] = value;
      setBatchList(list);
      // props.setSampleData(list)
      props.setSampleData(BatchList);
      console.log(BatchList);
    };
  
    const handleServiceRemove = (index) => {
      const list = [...BatchList];
      list.splice(index, 1);
      setBatchList(list);
      // props.setSampleData(list)
    };
  
    const handleServiceAdd = () => {
      setBatchList([...BatchList, { service: "" }]);
      // props.setSampleData([...BatchList, { service: "" }]);
    };
  
    return (
      <form className="App" autoComplete="off">
        <div className="form-field">
          <label htmlFor="service">Batches</label>
          {BatchList.map((singleService, index) => (
            <div key={index} className="services">
              <div className="first-division">
                <input
                  name="service"
                  type="text"
                  id="service"
                  value={singleService.service}
                  onChange={(e) => handleServiceChange(e, index)}
                  required
                />
                {BatchList.length - 1 === index && BatchList.length < 10 && (
                  <button
                    type="button"
                    onClick={handleServiceAdd}
                    className="add-btn"
                  >
                    <span>Add a Batch</span>
                  </button>
                )}
              </div>
              <div className="second-division">
                {BatchList.length !== 1 && (
                  <button
                    type="button"
                    onClick={() => handleServiceRemove(index)}
                    className="remove-btn"
                  >
                    <span>Remove</span>
                  </button>
                )}
              </div>
            </div>
          ))}
        </div>
      </form>
    );
  }
  
  export default Batch;