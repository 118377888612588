import React, {useState} from 'react'
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import {Box} from "@mui/material";
import Slide from '@mui/material/Slide';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { postRequest } from '../../utils/NetworkHandler';

const Transition = React.forwardRef((props, ref) => <Slide direction="left" ref={ref} {...props} />);


export default function AssignTeacherialog(props) {
    const [maxWidth, setMaxWidth] = React.useState('xs');
    const [fullWidth, setFullWidth] = React.useState(true);
    const [loading, setLoading] = useState(false);

  
    const {teachers} = props;
    const {studentId} = props;
    const [selected, setSelected] = useState("-1");
  

    const saveAccount = () => {
        setLoading(true);
        let formValidated = true;
        

        if(selected === "-1"){
          formValidated = false;
          alert("Please select a teacher");
        }
       

        if(formValidated){
            const data = {'student_id': studentId, "teacher_id": selected}
            postRequest("/landing/api/ummah/assign-teacher", data, onSuccess, onError, true);
        }
        else{
            setLoading(false);
        }

    }

    const onSuccess = (response)=> {
        setLoading(false);

        const {data} = response;
        if(data.status){
           
          setSelected("-1");
            handleClose();
            props.onRefresh();
        }
    }

    const onError = (error) => {
        setLoading(false);
        alert(error.toString());
    }

    const handleClose = () => {
        
        props.handleClose();

    }

    const handleTeacherChange = (e) => {
      setSelected(e.target.value);
    }
    

  return (
    <div>
      
      <Dialog open={props.open} onClose={handleClose}
      maxWidth={maxWidth}
      fullWidth={fullWidth}
        TransitionComponent={Transition}
      >
        <DialogTitle>Assign a Teacher</DialogTitle>
        <DialogContent>
          <DialogContentText>
            This action cannot be undone, please enter details with caution.
          </DialogContentText>
         


         <FormControl
      sx={{mt:2}}
      fullWidth>
        <InputLabel id="assign-to-teacher">Teacher</InputLabel>
        <Select
          labelId="assign-to-teacher"
          id="assign-to-teacher-select"
          value={selected}
          label="Assign To"
          onChange={handleTeacherChange}
        >
          <MenuItem value={"-1"}>Select a teacher</MenuItem>
          {teachers.map(( item, index) => (
          <MenuItem
          key={index}
          value={item.id}> {item.username}</MenuItem>
          ) )}
        </Select>
      </FormControl>


        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button disabled={loading}  onClick={() => saveAccount() }>Save</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}


