/* eslint-disable prefer-const */
/* eslint-disable prefer-template */
/* eslint-disable dot-notation */
import axios from 'axios';


// export const host = "http://192.168.0.179:8000";
// export const host ="http://127.0.0.1:8000";
export const host = "https://objects.ummah-app.com";
export const apptokenKey = "ummahAppToken";



    
export const getRequest = (endpoint, data, onSuccess, onError, isAuth) => {
    const url = host + endpoint;

    if(isAuth){
        if(data != null){
        data['Authorization'] = getAuth();
        data = {
            headers:data
        }
    }
    else{
        data = {
            headers: {
                Authorization: getAuth()
            }
        }
    }
        axios.get(url, data).then(response => {
            onSuccess(response);
        }).catch(error => {
            if(error.response.status === 401){
                window.location.href = "/";
            }
            onError(error);
        });
    }
    else{
        data= {
            headers: data
        }
        axios.get(url, data).then(response => {
            onSuccess(response);
        }).catch(error => {
            if(error.response.status === 401){
                window.location.href = "/";
            }
            onError(error);
        });
    }

   
}


export const postRequest = (endpoint, data, onSuccess, onError, isAuth) => {
    const url = host + endpoint;
    if(isAuth){
        axios.post(url, data,getAuthHeaders()).then(response => {
            onSuccess(response);
        }).catch(error => {
            onError(error);
        });
    }
    else{
        axios.post(url, data).then(response => {
            onSuccess(response);
        }).catch(error => {
            onError(error);
        });
    }
    
}


 function getAuthHeaders(){
    let headers = {
        headers: {
            Authorization: "Token "+ localStorage.getItem(apptokenKey)
        }
    };
    
    return headers;
    }


    
 function getAuth(){
    return "Token "+ localStorage.getItem(apptokenKey);
    }



