// components
import SvgIconStyle from '../../../components/SvgIconStyle';

// ----------------------------------------------------------------------

const getIcon = (name) => <SvgIconStyle src={`/icons/${name}.svg`} sx={{ width: 1, height: 1 }} />;

const ICONS = {
  user: getIcon('ic_user'),
  ecommerce: getIcon('ic_ecommerce'),
  analytics: getIcon('ic_analytics'),
  dashboard: getIcon('ic_dashboard'),
  volunteer:getIcon('volunteer'),
  job:getIcon('job'),
  activity:getIcon('activity'),
  mapping:getIcon('mapping'),
  form:getIcon('form'),
  madrasa:getIcon('madrasa'),
  announcement:getIcon('announcement'),
};

const sidebarConfig = [
  // GENERAL
  // ----------------------------------------------------------------------
  // {
  //   subheader: 'Admin',
  //   items: [
  //     { title: 'Dashboard', path: '/admin/dashboard', icon: ICONS.analytics },
  //   ],
    
  // },
  {
    subheader: 'Masjid',
    items: [
      { path: "/masjidadmin/masjid", title: "Profile", icon:ICONS.madrasa },
      { path: "/masjidadmin/masjidannouncements", title: "Announcements", icon: ICONS.announcement },
      { path: "/masjidadmin/masjidnamaztimings", title: "Namaz Timings", icon: ICONS.announcement },
    ],
    
  },
];

export default sidebarConfig;
