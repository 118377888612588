/* eslint-disable*/
import React, {useEffect, useState} from 'react'
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import {Box, Grid} from "@mui/material";
import Slide from '@mui/material/Slide';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import PhoneInput from "react-phone-input-2";
// import BatchCheckbox from '../components/BatchCheckbox';
import { postRequest } from '../../utils/NetworkHandler';
import "react-phone-input-2/lib/bootstrap.css"

const Transition = React.forwardRef((props, ref) => <Slide direction="left" ref={ref} {...props} />);


export default function EditTeacherDialog(props) {


    const [maxWidth, setMaxWidth] = React.useState('xs');
    const [fullWidth, setFullWidth] = React.useState(true);
    const [loading, setLoading] = useState(false);

    const [username, setUserName] = useState("");
    const [name, setName]=useState("");
    const [email, setEmail] = useState("");
    const [address, setAddress] = useState("");

    const [fullNameHelper, setFullNameHelper] = useState("");
    const [descHelper, setDescHelper]=useState("");
    const [feeHelper, setFeeHelper] = useState("");
    const [addressHelper, setAddressHelper] = useState("");
  

    const {batches} = props;

    const [batchData, setBatchData] = useState([]);

    function createBatchdata(id, checked, batch){
      return {id, checked, batch};
    }

    const {editObj} = props;

   
    useEffect( () => {
      if(editObj !== null && editObj !== undefined){
        

        const teacher_name = editObj.profile==null ? "" : editObj.profile.name;
        const teacher_email= editObj.profile==null ? "" : editObj.profile.email;
        const teacher_username = editObj.username;


        setName(teacher_name);
        setUserName(teacher_username);
        setEmail(teacher_email);
      }
    }, [editObj,] )
    
   

    const requiredText = "This field is required";

    const handleBatchCheckChange = (index, event) => {
      const data = [...batchData];
      const editRow = data[index];
      editRow.checked = event.target.checked;
      data[index] = editRow;
      setBatchData(data);

    }


    const saveAccount = () => {
        setLoading(true);
        const formValidated = true;
       
    //     if(username === ""){
    //         formValidated = false;
    //         setFullNameHelper(requiredText);
    //     }
    //     if(name === ""){
    //       formValidated = false;
    //       setDescHelper(requiredText);
    //   }
    //     if(address === ""){
    //       formValidated = false;
    //       setAddressHelper(requiredText);
    //     }
        
       
    //     if(isNaN(email) || email === "" ){
    //       formValidated = false;
    //       setFeeHelper("Please eneter a valid number");
    //     }
        
    
        if(formValidated){
          // console.log(username)
            const data = { 'edit_id': editObj.id, 'name':name, 'username': username, 'email': email,}
            postRequest(`/landing/api/ummah/editteacher/${editObj.id}`, data, onSuccess, onError, true);
        }
        else{
            setLoading(false);
        }

    }

    const onSuccess = (response)=> {
        setLoading(false);

        const {data} = response;
        if(data.status){
          
            setUserName("");
            setName("")
            setAddress("");
            setEmail("");
            handleClose();
            props.onRefresh();
        }
        else{
           
        }
    }

    const onError = (error) => {
        setLoading(false);
        alert(error.toString());
    }

    const handleClose = () => {
      
        setFullNameHelper("");
        setFeeHelper("");
        setAddressHelper("");
        props.handleClose();

    }

  
  return (
    <div>
      
      <Dialog open={props.open} onClose={handleClose}
      maxWidth={maxWidth}
      fullWidth={fullWidth}
        TransitionComponent={Transition}
      >
        <DialogTitle>Edit Teacher</DialogTitle>
        <DialogContent>
          
    
         <Box sx={{display:"flex", gap:'10px'}} >
         <Grid>
         <Grid item xs={7}>
         <PhoneInput
            country={"fi"}
            enableSearch
            value={username}
            onChange={(value) => setUserName(value)}
          />

         </Grid>
           

         <Grid item xs={7}>
         {/* <TextField
         fullWidth
            autoFocus
            margin="dense"
            id="name"
            label="Phone Number"
            type="text"
            error={fullNameHelper !== "" ? true: false }
            helperText={fullNameHelper}
            value={username}
            onChange={(e) => setUserName(e.target.value)}
            variant="standard"
          /> */}
          {/* <TextField
 fullWidth
            rows={1}
            autoFocus
            margin="dense"
            id="name"
            label="Name"
            type="text"
            // error={name !== "" ? true: false }
            helperText={descHelper}
            value={name}
            onChange={(e) => setName(e.target.value)}
            variant="standard"
          /> */}

         </Grid>  
         <Grid item xs={7}>
<TextField
 fullWidth
            rows={1}
            autoFocus
            margin="dense"
            id="name"
            label="Name"
            type="text"
            // error={name !== "" ? true: false }
            helperText={descHelper}
            value={name}
            onChange={(e) => setName(e.target.value)}
            variant="standard"
          />
</Grid>
<Grid item xs={7}>
<TextField
 fullWidth
            rows={2}
            autoFocus
            margin="dense"
            id="name"
            label="Email"
            type="text"
            // error={name !== "" ? true: false }
            helperText={descHelper}
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            variant="standard"
          />
  </Grid>
         </Grid>
        
         <Grid item xs={7}>
         <br/>
         </Grid>
         
        </Box>


        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button disabled={loading}  onClick={() => saveAccount() }>Save</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}


